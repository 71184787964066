import Config from "../Config";
import { RestService } from "./RestService";

export const ProfileService = {
  getProfile: async function () {
    return RestService.get(Config.profile_url, {}).then((response) =>
      response.json()
    );
  },
  createProfileEntry: async function (body, headers = {}) {
    return RestService.post(
      Config.profile_url,
      headers,
      body
    ).then((response) => response.json());
  },
  removeProfileEntry: async function (id) {
    return RestService.delete(
      Config.profile_entry_id_url.replace("{id}", id),
      {}
    );
  },
};
