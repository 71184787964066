import React from "react";
import PropTypes from 'prop-types';
import { fade, makeStyles } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        maxWidth: 220,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "black"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 180,
            '&:focus': {
                width: 220,
            },
        },
    },
}));

export default function SearchBar(props) {
    const classes = useStyles();

    const {onTextChange, className, hint, searchText, searchIconColor, onKeyUp} = props;

    return (
        <div className={clsx(classes.search, className)}>
            <div className={classes.searchIcon} style={searchIconColor ? {color: searchIconColor} : {}}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={hint || "Search…"}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => onTextChange(e)}
                value={searchText ? searchText : ""}
                onKeyUp={onKeyUp}
            />
        </div>
    );
};

SearchBar.propTypes = {
    hint: PropTypes.string,
    searchText: PropTypes.string,
    searchIconColor: PropTypes.string,
    onTextChange: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func
};