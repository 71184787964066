const host = process.env.NODE_ENV === 'production' ? "https://api.eauthenticity.com/" : "https://api.eauthenticity.com/";

const Config = {

    authenticate_url: `${host}authenticate`,
    authenticate_token_url: `${host}authenticate/token`,
    get_clients_url: `${host}clients`,
    change_password_url: `${host}change/password`,
    client_url: `${host}client`,
    request_reset_password_url: `${host}request/reset/password`,
    reset_password_url: `${host}reset/password`,
    product_url: `${host}product`,
    product_custom_field_url: `${host}product/fields/{productId}`,
    product_custom_field_delete_url: `${host}product/fields/{productId}/{fieldId}`,
    products_list_url: `${host}product/list`,
    product_id_url: `${host}product/{id}`,
    product_status_url: `${host}product/{id}/{status}`,
    product_qr_list_url: `${host}product/{id}/qrcode/list`,
    txn_logs_url: `${host}transaction/logs/list`,
    message_url: `${host}message`,
    message_list_url: `${host}message/list`,
    message_active_url: `${host}message/active`,
    message_id_url: `${host}message/{id}`,
    profile_url: `${host}profile`,
    profile_entry_id_url: `${host}profile/{id}`,
    customers_list_url: `${host}customers/list`,
    order_url: `${host}order`,
    order_status_url: `${host}order/status`,
    order_shipping_url: `${host}order/shipping`,
    order_payment_url: `${host}order/payment`,
    orders_list_url: `${host}orders/list`,
    order_details_url: `${host}order/items/{id}`,
    order_export_url: `${host}order/export/{id}`,
    shipment_vendor_url: `${host}shipment/vendor`,
    shipment_vendors_url: `${host}shipment/vendors`,
    shipment_vendor_id_url: `${host}shipment/vendor/{id}`,
    get_moq_url: `${host}moq`,
    get_config_by_type_url: `${host}configuration`,
    get_rates_url: `${host}rate/card`,
    update_config_url: `${host}configuration`,
    remove_config_url: `${host}configuration/{id}`,
    master_source_types_url: `${host}sources`,
    master_format_types_url: `${host}formats`,
    
    REFRESH_INTERVAL: 300000
}

export default Config;

export const RowCountOptions = [10, 25, 50, 100];