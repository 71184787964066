import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, IconButton, TableHead, TableCell, Fab } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip } from "../Utils";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MyDialog from "../components/MyDialog";
import SearchBar from "../components/SearchBar";
import { ProductService } from "../Services/ProductService";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCustomField from './AddCustomField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddProduct from "./AddProduct";
import AddIcon from '@material-ui/icons/Add';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles(theme => ({
    extendedIcon: {
        position: 'absolute',
        right: 30,
        bottom: 30,
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function Product(props){
    const { setTitle, setLinearLoading, showConfirmDialog, closeConfirmDialog, showSnackBar } = useContext(AppContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [data, setData] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Product', sortable: true },
        { id: 'description', align: 'left', numeric: false, disablePadding: true, label: 'Description', sortable: true },
        { id: 'next_sequence', align: 'left', numeric: false, disablePadding: true, label: 'QRs Generated', sortable: true },
        { id: 'status', align: 'left', numeric: false, disablePadding: true, label: 'Status', sortable: true },
        { id: 'actions', align: 'left', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.product_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.product_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    // Image Dialog
    const [showImageDialog, setShowImageDialog] = React.useState(false);
    const [dialogDetailsURL, setDialogDetailsURL] = React.useState(null);
    const [dialogDetailsTitle, setDialogDetailsTitle] = React.useState(null);

    // Custom Fields Dialog
    const [showCustomFieldFlag, setShowCustomFieldFlag] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState(null)

    // Add Custom Field
    const [addCustomFieldOpen, setAddCustomFieldOpen] = React.useState(false);
    const [addProductOpen, setAddProductOpen] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.product_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Manage Products');
        setGenerate(true);
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setGenerate(false);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // showProgressDialog();
            let fc = filter && filter.length > 0 ? filter : '';
            ProductService.getProductsCount(fc, '0,1')
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                if(toInt(data.count) === 0){
                    setData([]);
                    // closeProgressDialog();
                } else {
                    setFetchPage(true);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
        }
    }, [generate]);

    React.useEffect(() => {
        if(fetchPage) {
            setLinearLoading(true);
            let fc = filter && filter.length > 0 ? filter : '';
            ProductService.getProducts(fc, orderBy, order, '0,1', currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                setData(data);
                setLinearLoading(false);
                // closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                // closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const showCustomFields = (product) => {
        setShowCustomFieldFlag(true)
        setSelectedProduct(product)
    }
    
    const showAddCustomField = (product) => {
        setAddCustomFieldOpen(true)
        setSelectedProduct(product)
    }
    
    const edit = (product) => {
        setAddProductOpen(true);
        setSelectedProduct(product);
    }

    const getTranslatedDom = (type, value) => {
        switch(type){
            case 'link': return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>;
            default: return <span>{value}</span>;
        }
    }

    const removeField = (productId, field) => {
        showConfirmDialog('Confirm Remove?', undefined, () => {
            closeConfirmDialog()
            ProductService.removeProductField({}, productId, field.id)
            .then(() => {
                selectedProduct.fields = selectedProduct.fields.filter(f => f.id !== field.id)
                showSnackBar('Removed', 'info');
            })
            .catch(error => {
                console.log(error)
                showSnackBar('Something went wrong. Please try again.', 'error');
            })
        })
    }
    
    const changeStatus = (productId, status) => {           
        showConfirmDialog(`Confirm ${status === 1? 'Reactivation' : status === 0 ? 'Deactivation' : 'Deletion'} of Product?`, undefined, () => {
            closeConfirmDialog()
            ProductService.changeProductStatus({}, productId, status)
            .then(() => {
                setGenerate(true)
                showSnackBar('Changed Status', 'info');
            })
            .catch(error => {
                console.log(error)
                showSnackBar('Something went wrong. Please try again.', 'error');
            })
        })
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} lg={12}>
                                    <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                        <SearchBar 
                                            className={classes.searchBar}
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                                setFilter(e.target.value);
                                                setGenerate(true);
                                            }}
                                        />
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} lg={1}>
                                    <Tooltip arrow title={totalItems === 0 ? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={totalItems === 0} style={{marginTop: 8}} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid> */}
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {
                                                    data.map((element, index) => {
                                                        return (
                                                            <TableRow 
                                                                key={index} 
                                                                hover
                                                                style={{cursor: 'pointer'}}>
                                                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 130}}>
                                                                    {index+1}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="left" padding='none'>
                                                                    <div style={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                                                        <BootstrapTooltip title={element.image_url ? "Click to enlarge" : ""}>
                                                                            <div 
                                                                                style={{marginRight: 15, maxWidth: 110, cursor: 'pointer'}} 
                                                                                onClick={(e) => {
                                                                                    if(element.image_url){
                                                                                        setShowImageDialog(true);
                                                                                        setDialogDetailsTitle(element.name);
                                                                                        setDialogDetailsURL(element.image_url);
                                                                                    }
                                                                                }}>
                                                                                {
                                                                                    element.image_url && <img src={element.image_url ? element.image_url : null} height="50" width="50" alt="" style={{minWidth: 50, minHeight: 50}}/>
                                                                                }
                                                                            </div>
                                                                        </BootstrapTooltip>
                                                                        <div>
                                                                            <BootstrapTooltip title={element.name}>
                                                                                <div>
                                                                                    <span>{`${element.name.substring(0, 125)}${element.name.length > 125 ? '...' : ''}`}</span>
                                                                                </div>
                                                                            </BootstrapTooltip>
                                                                        </div>
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="left" padding='none'>
                                                                    {element.description || '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="left" padding='none'>
                                                                    {toInt(element.next_sequence || 0) - 1 || '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="left" padding='none'>
                                                                    {
                                                                        element.status === 1 ?  
                                                                                <IconButton component="span" style={{paddingTop: 0, paddingBottom: 0}} className={classes.green}>
                                                                                    <BootstrapTooltip title="Active" arrow>
                                                                                        <FiberManualRecordIcon />
                                                                                    </BootstrapTooltip>
                                                                                </IconButton>
                                                                        : 
                                                                                <IconButton className={classes.red} style={{paddingTop: 0, paddingBottom: 0}} component="span">
                                                                                    <BootstrapTooltip title="Deactive" arrow>
                                                                                        <FiberManualRecordIcon />
                                                                                    </BootstrapTooltip>
                                                                                </IconButton>
                                                                    }
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="left" padding='none'>
                                                                    <BootstrapTooltip title="View Custom Fields" arrow>
                                                                        <IconButton size="small" style={{marginRight: 8}} onClick={() => element.fields && showCustomFields(element)}>
                                                                            <LibraryBooksIcon />
                                                                        </IconButton>
                                                                    </BootstrapTooltip>
                                                                    <BootstrapTooltip title="Add Custom Fields" arrow>
                                                                        <IconButton size="small" style={{marginRight: 8}} onClick={() => element && showAddCustomField(element)}>
                                                                            <AddCircleIcon />
                                                                        </IconButton>
                                                                    </BootstrapTooltip>
                                                                    <BootstrapTooltip title="Edit" arrow>
                                                                        <IconButton size="small" style={{marginRight: 8}} onClick={() => element && edit(element)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </BootstrapTooltip>
                                                                    {
                                                                        toInt(element.next_sequence) < 2 && (
                                                                            <BootstrapTooltip title="Remove" arrow>
                                                                                <IconButton size="small" style={{marginRight: 8}} onClick={() => element && changeStatus(element.id, -1)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </BootstrapTooltip>
                                                                        )
                                                                    }
                                                                    {
                                                                        toInt(element.next_sequence) > 1 && (
                                                                            <BootstrapTooltip title={element.status === 1 ? `Deactivate` : `Reactivate`} arrow>
                                                                                <IconButton size="small" style={{marginRight: 8}} onClick={() => element && changeStatus(element.id, element.status === 1 ? 0 : 1)}>
                                                                                    <PowerSettingsNewIcon />
                                                                                </IconButton>
                                                                            </BootstrapTooltip>
                                                                        )
                                                                    }
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Products</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Fab variant="extended" color="primary" className={classes.extendedIcon} onClick={() => edit(null)}>
                    <AddIcon className={classes.eIcon} />
                    Add Product
                </Fab>
                <AddCustomField
                    {...props} 
                    onError={() => {
                        setAddCustomFieldOpen(false);
                        setSelectedProduct(null);
                    }} 
                    onSuccess={(field) => {
                        selectedProduct.fields.push(field)
                        setAddCustomFieldOpen(false);
                        setSelectedProduct(null);
                    }} 
                    onCancel={() => setAddCustomFieldOpen(false)} 
                    open={addCustomFieldOpen} 
                    product={selectedProduct}/>
                <AddProduct
                    {...props} 
                    onError={() => {
                        //setAddProductOpen(false);
                        //setSelectedProduct(null);
                    }} 
                    onSuccess={() => {
                        setAddProductOpen(false);
                        setSelectedProduct(null);
                        setGenerate(true)
                    }}
                    onCancel={() => setAddProductOpen(false)} 
                    open={addProductOpen} 
                    product={selectedProduct}/>
                <MyDialog
                    maxWidth={"xs"}
                    content={
                            <img 
                                src={dialogDetailsURL} 
                                height="250" width="250" 
                                alt=""/>
                        }
                    title={dialogDetailsTitle}
                    open={showImageDialog}
                    close={() => {
                        setShowImageDialog(false);
                        setDialogDetailsURL(null);
                        setDialogDetailsTitle(null);
                    }}/>
                <MyDialog
                    maxWidth={"sm"}
                    content={
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell>Content</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedProduct &&
                                    selectedProduct.fields.map(field => (
                                        <TableRow key={field.id}>
                                            <StyledTableCell>
                                                {field.label}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {getTranslatedDom(field.type, field.value)}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton onClick={() => removeField(selectedProduct.id, field)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                        }
                    title={selectedProduct ? selectedProduct.name : ''}
                    open={showCustomFieldFlag}
                    close={() => {
                        setShowCustomFieldFlag(false);
                        setSelectedProduct(null);
                    }}/>
            </div>
        </div>
    )
}