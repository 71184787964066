import React, { useContext, useState } from "react";
import "../css/home.css";
import {formatQty, formatNumber, getTimeInIST, format_display_fancy_notime_withDay, format_fancy, precision} from '../Utils';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import {
    Typography,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	Button,
	TextField,
	useTheme,
	Paper,
	makeStyles,
} from '@material-ui/core';
import Config from "../Config";
import { AppContext } from "../AppContextProvider";
import CardViewBlock from "../components/CardViewBlock";
import CardViewLoader from "../components/CardViewLoader";
import CustomDialog from "../components/CustomDialog";
import { OrdersService } from "../Services/OrdersService";
import { ROLES } from "../Roles";
import { MasterService } from "../Services/MasterService";
import { ManagementService } from "../Services/ManagementService";
import { Line } from "react-chartjs-2";
import CashBalance from "./CashBalance";
import { STATUS } from "../Constants";
import LoansDue from "./LoansDue";
import FollowUp from "./FollowUp";

const useStyles = makeStyles(theme =>({
	fab: {
		margin: theme.spacing(1),
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		paddingTop : theme.spacing(1), 
		paddingLeft: theme.spacing(1), 
		// paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	section: {
		width: '100%',
		minHeight: 100,
		overflow: 'auto',
		
		overflowX: 'auto',
		paddingTop : theme.spacing(1), 
		paddingLeft: theme.spacing(1), 
		// paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	tablecell: {
		fontSize: '12pt',
		padding: theme.spacing(1),
		
	},
	paper: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		width: "80%",
		outline: 'none',
		textAlign: 'center',
		color: theme.palette.text.secondary,
		marginBottom : '20px',
	},
	table: {
			display: 'inline-block',  
			[theme.breakpoints.up('md')]: {
				minWidth: 500,
				display: 'inline-table',  
			},
			overflow: 'auto'
	},
	list: {
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	grid: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		marginTop: '50px',
		marginBottom: '5px'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	qtyField: {
		width: "40%",
		position: "relative",
		[theme.breakpoints.up('md')]: {
			right: '100px',
			width: "30%",
		},
		right: "22px"
	},
	icon: {
		padding: '0px',
		[theme.breakpoints.up('md')]: {
			padding: '12px',
		},
	},
	itemQtySection: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '40%',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(3),
		minWidth: 200,
		marginLeft: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(0),
		},
	},
	button: {
		marginTop: theme.spacing(3),
		height: theme.spacing(7),
		float: 'right',
		marginLeft: theme.spacing(2),
	},
	message: {
		padding: 10,
		color: 'red'
	}
}));

function Home(props){
	const classes = useStyles();
	const theme = useTheme();
	const {state, setTitle, showConfirmDialog, closeConfirmDialog, showSnackBar} = useContext(AppContext);
	const [lastRefreshed, setLastRefreshed] = useState(moment());
	const [intervalID, setIntervalID] = useState(null);
	const [lastRefreshedString, setLastRefreshedString] = useState('');
	const [showBlockLoader, setShowBlockLoader] = useState(false);
	const [showBlockLoaderY, setShowBlockLoaderY] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialog, setDialog] = useState({});

	const [today, setToday] = React.useState([]);
	const [todayInterest, setTodayInterest] = React.useState(0);
	const [todayLoanAmountD, setTodayLoanAmountD] = React.useState(0);
	const [todayLoanAmountR, setTodayLoanAmountR] = React.useState(0);
	const [yesterday, setYesterday] = React.useState([]);
	const [yesterdayInterest, setYesterdayInterest] = React.useState(0);
	const [yesterdayLoanAmountD, setYesterdayLoanAmountD] = React.useState(0);
	const [yesterdayLoanAmountR, setYesterdayLoanAmountR] = React.useState(0);
	const [week, setWeek] = React.useState([]);
	const [weekInterest, setWeekInterest] = React.useState(0);
	const [weekLoanAmountD, setWeekLoanAmountD] = React.useState(0);
	const [weekLoanAmountR, setWeekLoanAmountR] = React.useState(0);
	const [goldRate, setGoldRate] = React.useState(0);
	const [schemes, setSchemes] = React.useState([]);
	// const [loanGivenData, setLoanGivenData] = React.useState([]);
	// const [interestCollectedData, setInterestCollectedData] = React.useState([]);
	const [newGoldRates, setNewGoldRates] = useState(new Map());
    const updateMap = (k,v) => {
        setNewGoldRates(new Map(newGoldRates.set(k,v)));
	}
	
	const [lineDataD, setLineDataD] = React.useState({}); 
	const [lineDataI, setLineDataI] = React.useState({}); 
	const options = {
        responsive : true, 
        maintainAspectRatio: false,
        layout: {
            padding: 32
        },
        elements: {
            arc: {
                borderWidth: 5,
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white
            },
        },
        tooltips: {
            // Disable the on-canvas tooltip
            intersect: false,
            caretSize: 10,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
			footerFontColor: theme.palette.white,
			callbacks: {
                label: function(tooltipItem, data) {
                    // var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    // if (label) {
                    //     label += ': ';
                    // }
                    var label = formatNumber(tooltipItem.yLabel);
                    return label;
                }
            }
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
							day: 'MMM DD',
                            week : "ll",
                            month: "MMM YYYY"
						},
						tooltipFormat: 'DD MMM YYYY'
                    }
                }
            ],
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                }
            }]
        }
    };

	React.useEffect(() => {
		if(state.isAuthenticated){
			setTitle('Dashboard');
			// fetchDashBoard();
			// refresh();
			timedEvents();
	
			// setIntervalID(setInterval(refresh, Config.REFRESH_INTERVAL));
		}
		return () => {
			clearInterval(intervalID);
			setIntervalID(null);
		}
	}, [state.isAuthenticated, true]);

	const fetchDashBoard = () => {
		setShowBlockLoaderY(true);
		let bIds = state.branches.length > 1 ? null : state.selectedBranch.id;
		Promise.all([
			OrdersService.getLoansDashboardCount('ACTIVE,CLOSED,CANCELLED', bIds, moment().add(-1, 'd'), moment().add(-1, 'd')),
			OrdersService.getLoansDashboardAmount(bIds, true, moment().add(-1, 'd'), moment().add(-1, 'd')),
			OrdersService.getLoansDashboardAmount(bIds, false, moment().add(-1, 'd'), moment().add(-1, 'd')),
			OrdersService.getLoansDashboardInterest(bIds, moment().add(-1, 'd'), moment().add(-1, 'd')),
			// LoanRequestsService.getLoansDashboardCount('ACTIVE,CLOSED,CANCELLED', bIds, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
			// LoanRequestsService.getLoansDashboardAmount(bIds, true, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
			// LoanRequestsService.getLoansDashboardAmount(bIds, false, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
			// LoanRequestsService.getLoansDashboardInterest(bIds, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
		])
		.then(datas => {
			setYesterday(datas[0]);
			setYesterdayLoanAmountD(datas[1] ? datas[1].loan_amount : 0);
			setYesterdayLoanAmountR(datas[2] ? datas[2].loan_amount : 0);
			setYesterdayInterest(datas[3] ? datas[3].interest : 0);

			// setWeek(datas[4]);
			// setWeekLoanAmountD(datas[5] ? datas[5].loan_amount : 0);
			// setWeekLoanAmountR(datas[6] ? datas[6].loan_amount : 0);
			// setWeekInterest(datas[7] ? datas[7].interest : 0);

			setShowBlockLoaderY(false);
		});
	}

	const timedEvents = () => {
		setLastRefreshedString(lastRefreshed.fromNow());
	}

	const refresh = () => {
		if(state.isAuthenticated){
			setShowBlockLoader(true);
			let bIds = state.branches.length > 1 ? null : state.selectedBranch.id;
			Promise.all([
				OrdersService.getLoansDashboardCount('ACTIVE,CLOSED,CANCELLED', bIds, moment(), moment()),
				OrdersService.getLoansDashboardAmount(bIds, true, moment(), moment()),
				OrdersService.getLoansDashboardAmount(bIds, false, moment(), moment()),
				OrdersService.getLoansDashboardInterest(bIds, moment(), moment()),
				MasterService.getSchemesList(moment(), moment()),
				ManagementService.getGoldRate(),
				OrdersService.getLoansDashboardAmountGraph(bIds, 'LOAN', moment().startOf('isoWeek'), moment().endOf('isoWeek')),
				OrdersService.getLoansDashboardAmountGraph(bIds, 'INTEREST_RECEIVED', moment().startOf('isoWeek'), moment().endOf('isoWeek')),
				
				OrdersService.getLoansDashboardCount('ACTIVE,CLOSED,CANCELLED', bIds, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
				OrdersService.getLoansDashboardAmount(bIds, true, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
				OrdersService.getLoansDashboardAmount(bIds, false, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
				OrdersService.getLoansDashboardInterest(bIds, moment().startOf('isoWeek'), moment().endOf('isoWeek')),
			])
			.then(datas => {
				setToday(datas[0]);
				setTodayLoanAmountD(datas[1] ? datas[1].loan_amount : 0);
				setTodayLoanAmountR(datas[2] ? datas[2].loan_amount : 0);
				setTodayInterest(datas[3] ? datas[3].interest : 0);
				setSchemes(datas[4] ? datas[4] : 0)
				setGoldRate(datas[5] ? datas[5]: undefined);

				// setLoanGivenData(datas[6] ? datas[6] : []);
				// setInterestCollectedData(datas[7] ? datas[7] : []);

				if(datas[6]){
					let labels = [];
					let datasetsCount = []
					let count = 0;
					let total = moment().endOf('isoWeek').diff(moment().startOf('isoWeek'), 'days');
					let start = moment().startOf('isoWeek');
					do{
						labels.push(start);
						getLineData2(datas[6], start);
						start = moment(start).add(1, 'day');
						count++;
					} while(count <= total);
					function getLineData2(datas, start){
						let data = datas.find(d => moment(d.date).isSame(start));
						datasetsCount.push(precision(data ? data.amount : 0));
					}
					setLineDataD({
						labels: labels, 
						datasets: [{
							label: "Loan Disbursed",
							data: datasetsCount,
							backgroundColor: '#FFF',
							borderColor: '#F85F73',
							borderWidth: 3,
							fill: false,
							pointRadius: 5,
						}]
					});
				}

				if(datas[7]){
					let labels = [];
					let datasetsCount = []
					let count = 0;
					let total = moment().endOf('isoWeek').diff(moment().startOf('isoWeek'), 'days');
					let start = moment().startOf('isoWeek');
					do{
						labels.push(start);
						getLineData(datas[7], start);
						start = moment(start).add(1, 'day');
						count++;
					} while(count <= total);
					function getLineData(datas, start){
						let data = datas.find(d => moment(d.date).isSame(start));
						datasetsCount.push(precision(data ? data.amount : 0));
					}
					setLineDataI({
						labels: labels, 
						datasets: [{
							label: "Interest Recovered",
							data: datasetsCount,
							backgroundColor: '#FFF',
							borderColor: '#3bed6b',
							borderWidth: 3,
							fill: false,
							pointRadius: 5,
						}]
					});
				}

				setWeek(datas[8]);
				setWeekLoanAmountD(datas[9] ? datas[9].loan_amount : 0);
				setWeekLoanAmountR(datas[10] ? datas[10].loan_amount : 0);
				setWeekInterest(datas[11] ? datas[11].interest : 0);

				setShowBlockLoader(false);
			});
			setLastRefreshed(moment());
		}
	}

	const handleChange = (type, id) => event => {
		let rate = isNaN(event.target.value) ? 0 : parseFloat(event.target.value);
		updateMap(id, { type, rate });
	};

	const updateRate = e => {
		console.log(newGoldRates);
		showConfirmDialog('Confirm Update of Rate?', undefined, () => {
			closeConfirmDialog();
			update();
		});
	}

	const update = () => {
		console.log(newGoldRates);
		let x = [];
		[...newGoldRates.keys()].forEach(key => {
			let v = newGoldRates.get(key);
			if(v.type === 'market') x.push(ManagementService.updateRate(v.rate));
			if(v.type === 'scheme') x.push(MasterService.updateSchemeGoldRate(key, v.rate));
		})
		Promise.all(x)
		.then(datas => {
			showSnackBar('Gold Rate Updated', 'success');
			handleClose(true);
		})
		.catch(error => {
			console.log(error);
		}) 
	}

	const openDialog = () => {
		setDialogOpen(true);
		let children = [], button = [];
		let rate = goldRate ? goldRate.rate : 0;
		children.push(
			<Grid item key="rate" xs={12} lg={12}>
				<TextField 
					label="Market" 
					fullWidth
					variant="outlined"
					defaultValue={rate || 0}
					onChange={handleChange('market', '-1')}
					type="number"
					InputProps={{
						startAdornment: <InputAdornment position="start">₹</InputAdornment>,
						'aria-label': 'rate',
						min: 0
					}}
					/>
			</Grid>
		);
		schemes.forEach(s => {
			children.push(
				<Grid item key={s.id} xs={12} lg={12}>
					<TextField 
						label={s.name} 
						fullWidth
						variant="outlined"
						defaultValue={s.amount || 0}
						onChange={handleChange('scheme', s.id)}
						type="number"
						InputProps={{
							startAdornment: <InputAdornment position="start">₹</InputAdornment>,
							'aria-label': 'rate',
							min: 0
						}}
						/>
				</Grid>
			);
		})
		button.push(
			<div key="update">
				<Button variant="outlined" color="primary" onClick={updateRate}>Update</Button>
			</div>
		);
		setDialog({
			title: "Upate Gold Rate",
			children,
			button
		})
	}

	const handleClose = (shouldRefresh = false) => {
		// if(shouldRefresh){
		// 	refresh();
		// }
		setDialog({});
		setDialogOpen(false);
		setNewGoldRates(new Map());
	}

	return (
		<div className="Home">
			<div className="lander">
				{
					state.user && (
						<div>
							<Grid container direction="row" spacing={1} justify={"flex-start"}>
								<Grid container item xl={12} lg={12} xs={12} spacing={0} justify={"space-between"}>
									<Grid item xl={12} lg={12} xs={12}>
										<div style={{float: "right"}}>
											<Typography variant="caption">
												Last Refreshed: 
												{
													lastRefreshed && lastRefreshedString
												}
											</Typography>
											<IconButton aria-label="close" data-tour="refresh" onClick={refresh}>
												<Icon>refresh</Icon>
											</IconButton>
										</div>
									</Grid>
								</Grid>
							</Grid>
							
							<CustomDialog 
								fullWidth
								maxWidth={'xs'}
								title={dialog.title} 
								buttonText={"CLOSE"} 
								buttons={dialog.button} 
								handleClose={() => handleClose(false)} 
								open={dialogOpen}
							>
								<Grid container spacing={2}>
									{dialog.children}
								</Grid>
							</CustomDialog>
						</div>
					)
				}
			</div>
		</div>
	);
}

export default Home;