import Config from '../Config';
import { RestService } from './RestService';

export const CustomerService = {
    getCustomersCount: async function(filter){
        return RestService.get(`${Config.customers_list_url}?count=true${ filter && filter.length > 0 ? `&filter=${filter}` : '' }`, {})
        .then(response => response.json());
    },
    getCustomers: async function(filter, sort, direction, start, offset){
        return RestService.get(`${Config.customers_list_url}?${ filter && filter.length > 0 ? `filter=${filter}` : '' }&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.json());
    },
    getCustomersExport: async function(filter, sort, direction, start, offset){
        return RestService.get(`${Config.customers_list_url}?export=true${ filter && filter.length > 0 ? `&filter=${filter}` : '' }&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.blob());
    },
};