import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, IconButton, Fab } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip, getTimeInIST, format_fancy } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SearchBar from "../components/SearchBar";
import { MessageService } from "../Services/MessageService";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    extendedIcon: {
        position: 'absolute',
        right: 30,
        bottom: 30,
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function Message(props){
    const { setTitle, setLinearLoading, showConfirmDialog, closeConfirmDialog, showSnackBar } = useContext(AppContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('modified_date');
    const [data, setData] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'description', align: 'justify', numeric: false, disablePadding: false, label: 'Description', sortable: false },
        { id: 'created_date', align: 'right', numeric: false, disablePadding: false, label: 'Created On', sortable: false },
        { id: 'status', align: 'left', numeric: false, disablePadding: true, label: 'Status', sortable: false },
        { id: 'action', align: 'left', numeric: false, disablePadding: true, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.messages_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.messages_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.messages_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Manage Messages');
        setGenerate(true);
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setGenerate(false);
            setData([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // showProgressDialog();
            let fc = filter && filter.length > 0 ? filter : '';
            MessageService.getMessagesCount(fc)
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                if(toInt(data.count) === 0){
                    setData([]);
                    // closeProgressDialog();
                } else {
                    setFetchPage(true);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
        }
    }, [generate]);

    React.useEffect(() => {
        if(fetchPage) {
            setLinearLoading(true);
            let fc = filter && filter.length > 0 ? filter : '';
            MessageService.getMessages(fc, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                setData(data)
                setLinearLoading(false);
                // closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                // closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const removeMessage = (id) => {
        showConfirmDialog('Confirm Remove', undefined, () => {
            closeConfirmDialog()
            MessageService.remove({}, id)
            .then(data => {
                showSnackBar('Removed Message', 'info')
                setGenerate(true)
            })
            .catch(error => {
                console.log(error)
                showSnackBar('Something went wrong. Please try again.', 'error')
            })
        })
    }

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const add = () => {
        showConfirmDialog('Enter Message', undefined, (message) => {
            if(message.length > 0){
                closeConfirmDialog();
                MessageService.createProduct({}, {message })
                .then(data => {
                    if(data.success){
                        showSnackBar('Message Created', 'info');
                    }
                    setFetchPage(true);
                })
                .catch(error => {
                    console.log(error)
                    showSnackBar('Something went wrong. Please try again later.', 'error');
                })
            }
        }, 'Cancel', 'Save', true, 'Message', '')
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} lg={12}>
                                    <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                        <SearchBar 
                                            className={classes.searchBar}
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                                setFilter(e.target.value);
                                                setGenerate(true);
                                            }}
                                        />
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} lg={1}>
                                    <Tooltip arrow title={totalItems === 0 ? "" : "Save Report"}>
                                        <IconButton size="medium" disabled={totalItems === 0} style={{marginTop: 8}} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid> */}
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {
                                                    data && data.map((element, index) => (
                                                        <TableRow 
                                                            key={index} 
                                                            hover
                                                            style={{cursor: 'pointer'}}>
                                                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 130}}>
                                                                {index+1}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none'>
                                                                {element.description || '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="right" padding='none'>
                                                                {element.created_date ? getTimeInIST(element.created_date).format(format_fancy) : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none' style={{width: '8vw'}}>
                                                                {
                                                                    element.status === 1 ?  
                                                                        <IconButton component="span" style={{paddingTop: 0, paddingBottom: 0}} className={classes.green}>
                                                                            <BootstrapTooltip title="Active" arrow>
                                                                                <FiberManualRecordIcon />
                                                                            </BootstrapTooltip>
                                                                        </IconButton>
                                                                    : 
                                                                        <IconButton className={classes.red} style={{paddingTop: 0, paddingBottom: 0}} component="span">
                                                                            <BootstrapTooltip title="Deactive" arrow>
                                                                                <FiberManualRecordIcon />
                                                                            </BootstrapTooltip>
                                                                        </IconButton>
                                                                }
                                                            </StyledTableCell>
                                                            <StyledTableCell scope="row" align="left" padding='none' style={{width: '5vw'}}>
                                                                <BootstrapTooltip title="Remove" arrow>
                                                                    <IconButton size="small" style={{marginRight: 8}} onClick={() => element && removeMessage(element.id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </BootstrapTooltip>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Messages</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Fab variant="extended" color="primary" className={classes.extendedIcon} onClick={() => add(null)}>
                    <AddIcon className={classes.eIcon} />
                    Add Message
                </Fab>
            </div>
        </div>
    )
}