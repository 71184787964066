import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, List, ListItem, Typography, ListItemText, Grid, CircularProgress, ListItemSecondaryAction } from "@material-ui/core";
import { BootstrapTooltip, getTimeInIST, format_fancy } from "../Utils";
// import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from "moment";
import { OrdersService } from "../Services/OrdersService";
import { AppContext } from "../AppContextProvider";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    inner: {
        overflow: 'auto',
    },
    comments: {
        display: 'flex',
        alignItems: 'center'
    },
    tableHead: {
        fontSize: 16,
        color: '#263238',
        fontWeight: 500,
        lineHeight: '1.5rem'
    },
    tableCell: {
        fontSize: '0.875rem',
        color: '#263238',
        fontWeight: 400,
        lineHeight: '1.43'
    }
}));

const FollowUp = ({count, reload, ...props}) => {
    const {state, openViewOrder, openUpdateShipmentInfo}  = useContext(AppContext);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    React.useEffect(() => {
        reloadContent()
    }, [reload]);
    
    const reloadContent = () => {
        setLoading(true);
        let bIds = state.branches.length > 1 ? null : state.selectedBranch.id;
        OrdersService.getFollowUps(moment().startOf('day'), moment().endOf('day'), bIds)
        .then(data => {
            console.log(data)
            setTotalCount(data ? data.length: 0)
            setData(data || []);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    return (
        <Grid item xl={12} lg={12} xs={12}>
            <Card 
                className={clsx(classes.root)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={`Follow Ups for today (${totalCount})`}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <div className={classes.inner}>
                            <List>
                                {data.map(followup => (
                                    <ListItem
                                        classes={{ divider: classes.itemDivider }}
                                        style={{paddingLeft: 10, paddingRight: 10}}
                                        divider
                                        dense
                                        key={followup.id}
                                    >
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <>
                                                    <BootstrapTooltip title={`Click to view Loan Details`}>
                                                        <div style={{display: 'flex', alignItems: 'space-between'}}>
                                                            <Typography 
                                                                style={{marginRight: 8, cursor: 'pointer'}} 
                                                                align="left" 
                                                                color="primary"
                                                                component="div"
                                                                onClick={(e) => {
                                                                    openViewOrder(followup.pledge_no)
                                                                }} 
                                                            >
                                                                <b>{followup.pledge_no}</b>
                                                            </Typography>
                                                            <Typography 
                                                                variant="subtitle2"
                                                                align="right" 
                                                                style={{lineHeight: '21px'}}
                                                                component="div">
                                                                {`${followup.customer_name} (${followup.customer_mobile})`}
                                                            </Typography>
                                                        </div>
                                                    </BootstrapTooltip>
                                                    <div>
                                                        <Typography 
                                                            variant="subtitle2"
                                                            align="left" 
                                                            style={{lineHeight: '34px', fontSize: 14}}
                                                            component="div">
                                                            {`Remark: ${followup.remarks}`}
                                                        </Typography>
                                                    </div>
                                                </>
                                            }
                                            secondary={
                                                <div style={{display: 'flex', alignItems: 'space-between'}}>
                                                    <Typography 
                                                        variant="caption" 
                                                        color="primary"
                                                        style={{marginRight: 8}} 
                                                        align="left" 
                                                        component="div">
                                                        {`Reminder: ${getTimeInIST(followup.next_follow_up).format(format_fancy)}`}
                                                    </Typography>
                                                    <Typography variant="caption" align="right" component="div">
                                                        {`Last Follow Up: ${getTimeInIST(followup.last_follow_up).format(format_fancy)}`}
                                                    </Typography>
                                                </div>
                                            }/>
                                            <BootstrapTooltip arrow title="Add Follow Up">
                                                <ListItemSecondaryAction style={{cursor: 'pointer'}}>
                                                    <SpeakerNotesIcon onClick={() => {
                                                        openUpdateShipmentInfo(followup.pledge_id, () => reloadContent())
                                                    }}/>
                                                </ListItemSecondaryAction>
                                            </BootstrapTooltip>
                                    </ListItem>
                                ))}
                                {
                                    data.length === 0 && (
                                        <ListItem
                                            style={{paddingLeft: 10, paddingRight: 10}}
                                            divider
                                            dense
                                            key={'no_loans'}
                                        >
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography align="left" color="secondary"><b>No Follow ups</b></Typography>
                                                }
                                            />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </div>
                    }
                </CardContent>
                {/* <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        variant="text"
                        to={`/report/pledge/due?type=ason&enddate=${moment().format(format_without_time)}`}
                    >
                        <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions> */}
            </Card>
        </Grid>
    );
};

export default FollowUp;