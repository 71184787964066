import Config from '../Config';
import {RestService} from './RestService';
import { Constants } from '../Constants';

export const UserService = {

    authenticate: async function(body, headers = {}){
        return RestService.post(Config.authenticate_url, headers, body)
                .then(response => response.json());
    },
    authenticateToken: async function(body, headers = {}){
        return RestService.post(Config.authenticate_token_url, headers, body)
                .then(response => response.json());
    },
    getClientList: async function(minimal = false){
        return RestService.get(`${Config.get_clients_url}?minimal=${minimal}`, {})
        .then(response => response.json());
    },
    toggleStatus: async function(id) {
        return RestService.get(Config.toggle_user_status.replace("{id}", id), {})
        .then(response => response.json())
    },
    removeUser: async function(id) {
        return RestService.delete(Config.remove_user.replace("{id}", id), {});
    },
    createEmployee: async function(headers, body) {
        return RestService.post(Config.create_user_internal, headers, body)
        .then(response => response.json());
    },
    getTotalCustomerCount: async function(filter = "", branches = null) {
        return RestService.get(`${Config.get_customer_list}filter=${filter}${branches ? `&branches=${branches}` : ''}&count=true`, {})
        .then(response => response.json());
    },
    getCustomerNameList: async function(filter = "") {
        return RestService.get(`${Config.get_customer_name_list}filter=${filter}`, {})
        .then(response => response.json());
    },
    getCustomerById: async function(id = "") {
        return RestService.get(Config.get_customer_by_id.replace("{id}", id), {})
        .then(response => response.json());
    },
    getBranchCount: async function(filter = "") {
        return RestService.get(`${Config.get_branches_url}?filter=${filter}&count=true`, {})
        .then(response => response.json());
    },
    getBranchList: async function(start, offset, filter, sort, direction){
        return RestService.get(`${Config.get_branches_url}&from=${start}&offset=${offset}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}&filter=${filter}`, {})
        .then(response => response.json());
    },
    removeBranch: async function(id) {
        return RestService.post(Config.remove_branch, {}, { id : id })
        .then(response => response.json());
    },
    getCustomersList: async function(branches, start, offset, filter, sort, direction){
        return RestService.get(`${Config.get_customer_list}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}&filter=${filter}${branches ? `&branches=${branches}` : ''}`, {})
        .then(response => response.json());
    },
    createBranch: async function(headers, body) {
        return RestService.post(Config.create_branch, headers, body)
        .then(response => response.json());
    },
    toggleBranchStatus: async function(id) {
        return RestService.get(Config.toggle_branch_status.replace("{id}", id), {})
        .then(response => response.json())
    },
    createCustomer: async function(headers, body) {
        return RestService.post(Config.create_user.replace("{type}", Constants.CUSTOMER), headers, body)
        .then(response => response.json());
    },
    getManagerHistory: async function(id){
        return RestService.get(Config.get_branch_manager_history.replace("{id}", id), {})
        .then(response => response.json());
    },


    getContractorList: async function(organisation){
        return RestService.get(`${Config.get_contractors_url}organisation=${organisation}&`, {})
        .then(response => response.json());
    },
    removeContractor: async function(organisation, id) {
        return RestService.post(`${Config.remove_contractor}organisation=${organisation}&id=${id}`, {})
        .then(response => response.json());
    },
    createContractor: async function(organisation, headers, body) {
        return RestService.post(`${Config.create_contractor}organisation=${organisation}&`, headers, body)
        .then(response => response.json());
    },

    // createUser: async function(headers, body, organisation) {
    //     return RestService.post(`${Config.create_user}organisation=${organisation}`, headers, body)
    //     .then(response => response.json());
    // },
    removeUsers: async function(id, organisation) {
        return RestService.post(`${Config.remove_users}organisation=${organisation}&id=${id}`, {})
        .then(response => response.json());
    },
    toggleStatusForUser: async function(id, organisation) {
        return RestService.get(`${Config.toggle_users_status}organisation=${organisation}&user_id=${id}`, {})
        .then(response => response.json())
    },
    changePhoto: async function(customerId, body, headers = {}) {
        return RestService.postFormData(Config.change_customer_documentation.replace("{id}", customerId), headers, body)
        .then(response => response.json());
    },
    uploadKYC: async function(customerId, body, headers = {}) {
        return RestService.postFormData(Config.upload_customer_kyc.replace("{id}", customerId), headers, body)
        .then(response => response.json());
    },
};