import React, { useContext, useEffect } from 'react'
import { AppContext } from '../AppContextProvider';

function OpenOrderDetails(props) {

    const {openViewOrder} = useContext(AppContext)
    const { match } = props;
    const no = match.params.no || null;

    useEffect(() => {
        if(no) {
            openViewOrder(no);
        }
    }, [no])

    return (
        <div>
            
        </div>
    )
}

export default OpenOrderDetails
