import React, { useContext, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { ButtonBase, CardHeader, Divider, FormControl, Grid, Icon, IconButton, makeStyles, Table, TableBody, TableRow, Typography } from '@material-ui/core';
import { ProductService } from '../Services/ProductService';
import { AppContext } from '../AppContextProvider';
import {linkPreventCaching, StyledTableCell} from '../Utils'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import ItemSelection from '../components/ItemSelection';
import { FIELD_TYPES } from '../Constants';
import EnhancedTableHead from '../components/EnhancedTableHead';
let counter = 0
const useStyles = makeStyles((theme) => ({
    action: {
        marginTop: 0
    },
    rootCard: {
        padding: '8px 0px'
    },
    root: {
        minHeight : 90,
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        // },
    },
    image: {
		// margin: 12,
		position: 'relative',
		height: 150,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &$focusVisible': {
			zIndex: 1,
		'& $imageBackdrop': {
			opacity: 0.7,
		},
		'& $imageMarked': {
			opacity: 0,
		},
		'& $imageTitle': {
			border: '4px solid currentColor',
		},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
  }));

let minSize = 0    // in KB
let minSizeText = '20KB'    // 20KB
let maxSize = 2048 // in KB
let maxSizeText = '2MB' // 2MB
let imageWidth = 1200 // In pixel
let imageHeight = 1200 // In pixel

export default function AddProduct(props) {
    const {showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
    const { open, product, onSuccess, onError, onCancel } = props;
    const classes = useStyles();
    const [errorFieldRow, setErrorFieldRow] = React.useState([]);
    const [newProduct, setNewProduct] = React.useState(null);
    const [fields, setFields] = React.useState([]);
    const [imageFile, setImageFile] = React.useState(null);
    const [error, setError] = React.useState({
        name: false,
        description: false,
        image_file: false
    });

    const columns = [
        { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'label', align: 'center', numeric: false, disablePadding: false, label: 'Label', sortable: false },
        { id: 'value', align: 'center', numeric: false, disablePadding: false, label: 'Value', sortable: false },
        { id: 'type', align: 'center', numeric: false, disablePadding: true, label: 'Type', sortable: false },
        { id: 'action', align: 'center', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    React.useEffect(() => {
        if(open){
            if(product){
                setNewProduct(product);
                let errRows = [], rows = [];
                product.fields.forEach((i, index) => {
                    let x = {
                        id: i.id,
                        rowid: counter++,
                        type: FIELD_TYPES.find(ii => ii.key === i.type),
                        label: i.label,
                        value: i.value
                    };
                    rows.push(x);
                    errRows.push({...x,
                        type: false, label: false, value: false
                    })
                });
                setFields(rows)
                setErrorFieldRow(errRows);
            }
        }
    }, [open]);

    const handleChange = event => {
        event.persist()
        setNewProduct(p => ({...p, [event.target.name]: event.target.value}))
    };

    const validate = () => {
        console.log(newProduct, newProduct.name.length === 0)
        let isValid = true;
        if(newProduct.name.length === 0){
            isValid = false;
        }
        setError(e => ({...e, name: newProduct.name.length === 0}))
        if(newProduct.description.length === 0){
            isValid = false;
        }
        setError(e => ({...e, description: newProduct.description.length === 0}))
        if(!imageFile && (newProduct === null || (newProduct !== null && !newProduct.image_file))){
			isValid = false;	
		}
        setError(e => ({...e, image_file: !newProduct.image_file}))

        
        let i = fields.filter(i => !i.type || !i.value || !i.label);
        if(i.length > 0){
            isValid = false;
            i.forEach(i => {
                setErrorFieldRow(rows => rows.map(row => 
                    row.rowid === i.rowid
                    ?
                    {...row, type: !i.type, value: !i.value, label: !i.label}
                    :row
                ));
            })
        }

        return isValid;
    }

    const save = () => {
        if(validate()){
            showConfirmDialog('Confirm save Product?', undefined, () => {
                closeConfirmDialog();
                showProgressDialog(`Saving Product ${newProduct.name}...`);
                const formData = new FormData();
                if(newProduct.id){
                    formData.append('id', newProduct.id || null);
                }
                formData.append('name', newProduct.name || null);
                formData.append('description', newProduct.description || null);
                formData.append('image_file', imageFile || null);
                let fList = fields.map(i => i.id ? ({
                    id: i.id || null,
                    type: i.type.key,
                    label: i.label,
                    value: i.value,
                }): ({
                    type: i.type.key,
                    label: i.label,
                    value: i.value,
                }));
                formData.append('custom_fields', JSON.stringify(fList));

                ProductService.createProduct({}, formData)
                .then((data) => {
                    if(data.success){
                        showSnackBar('Product Saved', "info");
                        onSuccess();
                        closeProgressDialog();
                    } else {
                        showSnackBar('Something went wrong. Please try again.', "error");
                        closeProgressDialog();
                    }
                })
                .catch(error => {
                    console.log(error);
                    onError();
                    showSnackBar('Something went wrong. Please try again later', "error");
                    closeProgressDialog();
                })
            })
        }
    }

    const handleClose = () => {
        resetForm();
        onCancel();
    }

    const resetForm = () => {
        setError({
            name: false,
            description: false,
            image_file: false
        })
        setNewProduct(null);
    }

    const changePhoto = (field, file) => {
		console.log(field, file);
		if(field === 'image_file'){
			setImageFile(file);
		}
		setNewProduct(product => ({...product, [field]: file}));
	}

    const handleRowChange = rowid => e => {
        e.persist()
		let name = e.target.name;
		let value = e.target.value;
		setFields(fields.map(row => 
			row.rowid === rowid
			?
				{...row, [name]: value}
			:	
				row
			)
		);
		setErrorFieldRow(rows => rows.map(row => 
			row.rowid === rowid
			?
			{...row, [name]: !value}
			:row
		));
	}

    const handleRowItemChange = rowid => type => {
		setFields(fields.map(row => 
			row.rowid === rowid
			?
			{...row, type}
			:row
			)
		);
		setErrorFieldRow(rows => rows.map(row => 
			row.rowid === rowid
			?
			{...row, type: !type}
			:row
		));
	}

    const addRow = () => {
		let row = {
            rowid : counter++,
            type: null,
            label: null,
			value: null,
        }
        setFields([...fields, row]);
        setErrorFieldRow([...errorFieldRow, {...row, type: true, value: true, label: true}]);
	}

    const handleRowRemove = rowid => e => {
		setFields(fields.filter(r => r.rowid !== rowid));
	}
    
    return (
        <Dialog fullWidth onClose={handleClose} maxWidth="md" open={open} aria-labelledby="form-dialog-title">
        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {
                        newProduct && newProduct.image_url ?
                            (<ImageHolder 
                                title="Change Product Image" 
                                link={newProduct.image_url} 
                                change={(file) => {
                                    changePhoto('image_file', file);
                                }} 
                            />)
                        :
                            (<ImagePlaceHolder
                                error={error.image_file}
                                title="Add Product Image"
                                link={null} 
                                change={(file) => {
                                    changePhoto('image_file', file);
                                }} 
                            />)
                    }
                    <Typography component="div" variant="caption" style={{marginLeft: 6}}>
                        {`Max size ${maxSizeText} with min resolution ${imageWidth}x${imageHeight}`}
                    </Typography>
                </Grid>
                <Grid container item xs={8}>
                    <Grid item xs={12}>
                        <FormControl fullWidth className={classes.root}>
                            <TextField
                                autoFocus
                                label='Product Name'
                                name="name"
                                defaultValue={newProduct ? newProduct.name : null}
                                type="text"
                                required
                                fullWidth
                                helperText={`${(newProduct && newProduct.name ? newProduct.name.length : 0)} / 150`}
    //                            helperText={ asin && (asin.length < 10 ? 'Keep Typing more characters' : (excludeList.includes(asin) ? 'Its already Added' : '' ))}
                                error={error.name}
                                variant="outlined"
                                onChange={handleChange}
                                InputProps={{
                                    inputProps: { min: 1, maxLength: 150 }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth className={classes.root}>
                            <TextField
                                label='Product Description'
                                type="text"
                                required
                                name="description"
                                defaultValue={newProduct ? newProduct.description : null}
                                fullWidth
                                rows={2}
                                multiline
                                rowsMax={3}
    //                            helperText={ asin && (asin.length < 10 ? 'Keep Typing more characters' : (excludeList.includes(asin) ? 'Its already Added' : '' ))}
                                error={error.name}
                                variant="outlined"
                                onChange={handleChange}
                                helperText={`${(newProduct && newProduct.description ? newProduct.description.length : 0)} / 1500`}
                                InputProps={{
                                    inputProps: { min: 0, maxLength: 1500 }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider variant="inset"/>
                </Grid>
                <Grid item xs={12}>
                    <CardHeader
                        classes={{
                            action: classes.action,
                            root: classes.rootCard
                        }}
                        title="Custom Fields"
                        titleTypographyProps={{variant: 'h4'}}
                        action={
                            <IconButton aria-label="add-row" onClick={addRow}>
                                <AddCircleIcon />
                            </IconButton>
                        }
                    />
                    <Divider/>
                    <Table>
                        <EnhancedTableHead
                            order={""}
                            orderBy={""}
                            onRequestSort={() => {}}
                            rows={columns}  />
                        <TableBody>
                            {fields.map((row, i) => 
                                <TableRow key={i} hover>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        <div>{i + 1}</div>
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none'>
                                        <TextField 
                                            name="label"
                                            variant="outlined"
                                            margin="dense"
                                            error={errorFieldRow.find(i => i.rowid === row.rowid).label || false}
                                            value={row.label ? row.label : ''} 
                                            inputProps={{
                                                minLength: 1
                                            }}
                                            required
                                            style={{marginTop: 0}}
                                            type="text"
                                            onChange={handleRowChange(row.rowid)}/>
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none'>
                                        <TextField 
                                            name="value"
                                            error={errorFieldRow.find(i => i.rowid === row.rowid).value || false}
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            value={row.value ? row.value : ''} 
                                            inputProps={{
                                                minLength: 1
                                            }}
                                            style={{marginTop: 0}}
                                            type="text"
                                            onChange={handleRowChange(row.rowid)}/>
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none' style={{minWidth: '20vw', marginBottm: 8}}>
                                        <ItemSelection
                                            required 
                                            label='Type'
                                            value={row.type || null} 
                                            optionLabel='label'
                                            error={errorFieldRow.find(i => i.rowid === row.rowid).item || false}
                                            options={FIELD_TYPES} 
                                            style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
                                            formClass={classes.formAutoClass}
                                            selected={handleRowItemChange(row.rowid)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none'>
                                        <Button style={{padding: 0}}>
                                            <Icon style={{marginTop: 16, marginBottom: 22}} onClick={handleRowRemove(row.rowid)}>delete</Icon>
                                        </Button>
                                    </StyledTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={save} color="primary">
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
}

function ImagePlaceHolder(props){
	let classes = useStyles();
	const {error} = props;
    const {showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const [link, setLink] = React.useState(linkPreventCaching(props.link));

	const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
        if(fileUploaded){
            if((fileUploaded.size/1024) > minSize && (fileUploaded.size/1024) < maxSize){
                setLink(URL.createObjectURL(fileUploaded));
                props.change(fileUploaded);
            } else {
                showConfirmDialog1D(`Please choose a file of min size ${minSizeText} and max size of ${maxSizeText}`, undefined, () => {
                    closeConfirmDialog1D()
                    handleClick()
                }, 'OK')
            }
        }
	}

	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			{
				link && (
					<img 
						src={link} 
						height="150" width="150" 
						alt=""/>
				)
			}
			<span className={classes.imageBackdrop} style={{backgroundColor : error || false ? 'red' : ''}}/>
			<span className={classes.imageButton} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p><AddCircleIcon fontSize="large"/></p>
					{props.title}
					<span className={classes.imageMarked} />
					<input accept="image/*" type="file" onChange={change} ref={hiddenFileInput} style={{display:'none'}} />
				</Typography>
			</span>
		</ButtonBase>
	)
}

function ImageHolder(props){
	const hiddenFileInput = React.useRef(null);
	const [link, setLink] = React.useState(linkPreventCaching(props.link));
    const {showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
    const imageRef = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
        if(fileUploaded){
            if((fileUploaded.size/1024) > minSize && (fileUploaded.size/1024) < maxSize){
                setLink(URL.createObjectURL(fileUploaded));
                imageRef.current.onload = function() {
                    let width = imageRef.current.naturalWidth;
                    let height = imageRef.current.naturalHeight;
                    if(width > imageWidth && height > imageHeight){
                        console.log(width, height);
                        props.change(fileUploaded);
                    } else {
                        showConfirmDialog1D(`Please choose a file of min dimensions ${imageWidth}x${imageHeight}`, undefined, () => {
                            closeConfirmDialog1D()
                            handleClick()
                        }, 'OK')
                    }
                }
            } else {
                showConfirmDialog1D(`Please choose a file of min size ${minSizeText} and max size of ${maxSizeText}`, undefined, () => {
                    closeConfirmDialog1D()
                    handleClick()
                }, 'OK')
            }
        }
	}

	let classes = useStyles();
	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<img 
				src={link} 
                ref={imageRef}
				height="150" width="150" 
				alt=""/>
			<span className={classes.imageBackdrop}/>
			<span className={classes.imageButtonBottom} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p><EditIcon fontSize="small"/></p>
					{props.title}
					<span className={classes.imageMarked} />
				</Typography>
				<input accept="image/*" type="file" onChange={change} ref={hiddenFileInput} style={{display:'none'}} />
			</span>
		</ButtonBase>
	)
}

AddProduct.propTypes = {
    open: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    product: PropTypes.object
  };