import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Grid } from '@material-ui/core';

import MOQConfig from './MOQConfig';
import RateCard from './RateCard';
import { AppContext } from '../AppContextProvider';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const ManageConfig = props => {
  const { match, history } = props;
  const classes = useStyles();
  const tab = match.params.tab || 'general';

  const {setTitle} = useContext(AppContext);

//   console.log(tab);

  React.useEffect(() => {
      setTitle('Manage Configuration');
  }, [true]);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'moq', label: 'Minimum Order Quantity' },
    { value: 'rate', label: 'Manage Rates' },
  ];

  if (!tab) {
    return <Redirect to="/manage/config/moq" />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <div className="Home">
        <div className="lander">
            <Grid container justify="center" alignItems="flex-start">
                <Grid item xs={12} lg={10} xl={10}>
                    <Tabs
                        className={classes.tabs}
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={tab}
                        variant="scrollable"
                    >
                        {tabs.map(tab => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                        />
                        ))}
                    </Tabs>
                    <Divider className={classes.divider} />
                    <div className={classes.content}>
                        {tab === 'moq' && <MOQConfig {...props}/>}
                        {tab === 'rate' && <RateCard {...props}/>}
                    </div>
                </Grid>
            </Grid>
        </div>
    </div>
  );
};

ManageConfig.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default ManageConfig;
