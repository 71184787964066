import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, Table, TableBody, TableRow, CircularProgress, Backdrop, Link, Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { StyledTableCell, getTimeInIST, getStatusBadge, getColor, formatNumber, format_fancy, titleCase } from '../Utils';
import { AppContext } from '../AppContextProvider';
import EnhancedTableHead from '../components/EnhancedTableHead';
import { OrdersService } from '../Services/OrdersService';
import { STATUS } from '../Constants';
import { ROLES } from '../Roles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PaymentIcon from '@material-ui/icons/Payment';
import PrintIcon from '@material-ui/icons/Print';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	titleHeader: {
		fontSize: 14,
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	header: {
		fontWeight: 700
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 8,
		padding: 8,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &$focusVisible': {
			zIndex: 1,
		'& $imageBackdrop': {
			opacity: 0.7,
		},
		'& $imageMarked': {
			opacity: 0,
		},
		'& $imageTitle': {
			border: '4px solid currentColor',
		},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	formControl: {
        margin: 0,
    },
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewOrderDialog(props) {
	const {state, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [order, setOrder] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [isSuperAdmin, setIsSuperAdmin] = React.useState(true);
	const steps = getSteps();
	const [activeStep, setActiveStep] = React.useState(0)

	const columns = [
        { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'product', align: 'left', numeric: false, disablePadding: true, label: 'Product', sortable: false },
        { id: 'count', align: 'right', numeric: true, disablePadding: false, label: 'Count', sortable: false },
        // { id: 'size', align: 'right', numeric: true, disablePadding: false, label: 'Size', sortable: false },
        { id: 'format', align: 'right', numeric: true, disablePadding: false, label: 'Format', sortable: false },
	];

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			if(state.user.roles.includes(ROLES.SUPER_ADMIN)){
				setIsSuperAdmin(true)
			}
			showProgressDialog();
			fetchData();
			setLoading(true);
		}
		return () => {
			setOrder({});
			setOpen(false);
		}
	},[props.open]);

	const fetchData = () => {
		OrdersService.getOrderDetails(id)
		.then(data => {
			console.log(data);
			if(data){
				setActiveStep(data.status);
				setOrder(data);
			}
			closeProgressDialog();
			setLoading(false);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
				handleClose();
				closeConfirmDialog();
			});
			setLoading(false);
			closeProgressDialog();
		});
	}

	const handleClose = (callback = false) => {
		props.onClose(callback);
		setOpen(false);
	};

	// const showCancelConfirm = () => {
	// 	showConfirmDialog('Are you sure you want to cancel the Order?', undefined, () => {
	// 		closeConfirmDialog();
	// 		cancel();
	// 	}, 'Back')
	// }

	// const cancel = () => {
	// 	setLoading(true);
	// 	OrdersService.cancelRequest(id)
	// 	.then(data => {
	// 		showSnackBar('Loan Cancelled', 'info');
	// 		handleClose(true);
	// 		setLoading(false);
	// 	})
	// 	.catch(error => {
	// 		console.log(error);
	// 		showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
	// 			handleClose();
	// 			closeConfirmDialog();
	// 		});
	// 		setLoading(false);
	// 	})
	// }

	// const exportReport = type => e => {

	// 	// let rows = [];
	// 	// let data = type === 'standard' ? standardTransactions: subTransactions;
    //     // let filename = 'Interest Calculation';
    //     // rows.push(['Scheme' , type === 'standard' ? order.scheme.name : order.sub_scheme.name])
    //     // rows.push(['Interest' , `${type === 'standard' ? standardInterest : subInterest}%`])
	// 	// rows.push(type === 'standard' ? columnsT.filter(c => c.id !== 'no_of_months' && c.id !== 'paid_upto').map(c => c.label) : columnsT.filter(c => c.id !== 'no_of_months').map(c => c.label));
	// 	// data.forEach((d, index) => {
	// 	// 	if(type === 'standard'){
	// 	// 		rows.push([d.txn_date, d.narration, d.debit, d.credit, d.balance, d.no_of_days, d.interest]);
	// 	// 	} else {
	// 	// 		rows.push([d.txn_date, d.paid_upto, d.narration, d.debit, d.credit, d.balance, d.no_of_days, d.interest]);
	// 	// 	}
	// 	// });
	// 	// rows.push([postingDate.format(format_display)]);
	// 	// const wb = XLSX.utils.book_new();
	// 	// const wsAll = XLSX.utils.aoa_to_sheet(rows);
	// 	// XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
	// 	// XLSX.writeFile(wb, filename + ".xlsx");
    // }

	const ColorlibConnector = withStyles({
		alternativeLabel: {
		  top: 22,
		},
		active: {
		  '& $line': {
			backgroundImage:
			  'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		  },
		},
		completed: {
		  '& $line': {
			backgroundImage:
			  'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		  },
		},
		line: {
		  height: 3,
		  border: 0,
		  backgroundColor: '#eaeaf0',
		  borderRadius: 1,
		},
	})(StepConnector);

	function getSteps() {
		return Object.keys(STATUS).filter(k => k !== 'CANCELLED').map(k => titleCase(k));
	}

	const useColorlibStepIconStyles = makeStyles({
		root: {
		  backgroundColor: '#ccc',
		  zIndex: 1,
		  color: '#fff',
		  width: 50,
		  height: 50,
		  display: 'flex',
		  borderRadius: '50%',
		  justifyContent: 'center',
		  alignItems: 'center',
		},
		active: {
		  backgroundImage:
			'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
		  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		},
		completed: {
		  backgroundImage:
			'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
		},
	  });

	function ColorlibStepIcon(props) {
		const classes = useColorlibStepIconStyles();
		const { active, completed } = props;
	  
		const icons = {
		  1: <ShoppingCartIcon />,
		  2: <PaymentIcon />,
		  3: <PrintIcon />,
		  4: <DescriptionIcon />,
		  5: <LocalShippingIcon />,
		  6: <CheckCircleIcon />,
		};
	  
		return (
		  <div
			className={clsx(classes.root, {
			  [classes.active]: active,
			  [classes.completed]: completed,
			})}
		  >
			{icons[String(props.icon)]}
		  </div>
		);
	}


  	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{id && `Order No: ${order.order_no}` }
				</Typography>
				</Toolbar>
			</AppBar>
			<Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop>
			<div className={classes.page}>
				<Grid container spacing={0} style={order.status && order.status === STATUS.CANCELLED ? {backgroundColor: getColor(order.status)} : {}} alignItems="flex-start" justify="flex-start" direction="row">
					{
						order && (
							<Grid item xs={12} lg={12}>
								<Paper variant="outlined" elevation={3}>
									<Stepper style={{padding: 16}} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
										{steps.map((label) => (
											<Step key={label}>
												<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
											</Step>
										))}
									</Stepper>
								</Paper>
							</Grid>
						)
					}
					{
						order.customer && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Customer information"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8}}>
									<Grid container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
											<Grid item xs={12} lg={1}>
												<Typography variant="h5" className={classes.header}>Name</Typography>
											</Grid>
											<Grid item xs={12} lg={2}>
												<Typography variant="subtitle1">{order.customer.name || ''}</Typography>
											</Grid>
											<Grid item xs={12} lg={1}>
												<Typography variant="h5" className={classes.header}>Code</Typography>
											</Grid>
											<Grid item xs={12} lg={2}>
												<Typography variant="subtitle1">{order.customer.cust_code || ''}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						order.items && (
						<Grid item xs={12} lg={8}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Order items"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<Table>
									<EnhancedTableHead
										order={""}
										orderBy={""}
										onRequestSort={() => {}}
										rows={columns}  />
									<TableBody>
										{order.items.map((row, i) => 
											<TableRow key={i} hover>
												<StyledTableCell scope="row" align="justify" padding='none'>
													{i + 1}
												</StyledTableCell>
												<StyledTableCell scope="row" align="left" padding='none' style={{minWidth: '20vw', marginBottm: 8}}>
													{row.product_details.name}
												</StyledTableCell>
												<StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 32}}>
													{row.count}
												</StyledTableCell>
												{/* <StyledTableCell scope="row" align="right" padding='none'style={{paddingRight: 32}}>
													{row.size}
												</StyledTableCell> */}
												<StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 32}}>
													{titleCase(row.format)}
												</StyledTableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Paper>
						</Grid>)
					}
					{
						order && (
							<Grid item xs={12} lg={4}>
								<Paper variant="outlined" className={classes.section} elevation={3}>
									<CardHeader
										title="Order Summary"
										titleTypographyProps={{variant: 'h4'}}
									/>
									<Divider/>
									<Grid container spacing={0} alignItems="center">
										{
											isSuperAdmin && (
											<Grid item xs={6} lg={6}>
												<Typography variant="body2" className={classes.header}>Client</Typography>											
											</Grid>	
										)}
										<Grid item xs={6} lg={6}>
											<Typography variant="body2">{order.client_name|| ''}</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2" className={classes.header}>Order No</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2">{order.order_no}</Typography>
										</Grid>
										<Grid item xs={6} lg={6}>
											<Typography variant="body2" className={classes.header}>Total Amount</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2">{formatNumber(order.amount)}</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2" className={classes.header}>Order Date</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2">{getTimeInIST(order.order_date).format(format_fancy)}</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2" className={classes.header}>Order Status</Typography>
										</Grid>	
										<Grid item xs={6} lg={6}>
											<Typography variant="body2">{getStatusBadge(`${order.status}`)}</Typography>
										</Grid>	
										{
											order.payment_date && (
											<Grid item xs={12} container style={{marginTop: 16}}>
												<Grid item xs={12} lg={12}>
													<Divider/>
												</Grid>	
												<Grid item xs={12} lg={12}>
													<Typography className={classes.titleHeader} color="textSecondary" gutterBottom>Payment Details</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2" className={classes.header}>Mode</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2">{order.payment_mode}</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2" className={classes.header}>Paid On</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2">{order.payment_date ? getTimeInIST(order.payment_date).format(format_fancy) : '-'}</Typography>
												</Grid>
											</Grid>
										)}
										{
											order.shipment_date && (
											<Grid item xs={12} container style={{marginTop: 16}}>
												<Grid item xs={12} lg={12}>
													<Divider/>
												</Grid>	
												<Grid item xs={12} lg={12}>
													<Typography className={classes.titleHeader} color="textSecondary" gutterBottom>Shipment Details</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2" className={classes.header}>Mode</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2">{order.shipment_vendor_name}</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2" className={classes.header}>Shipped on</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2">{order.shipment_date? getTimeInIST(order.shipment_date).format(format_fancy) : '-'}</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2" className={classes.header}>Tracking Details</Typography>
												</Grid>	
												<Grid item xs={6} lg={6}>
													<Typography variant="body2">
														{
															order.shipment_tracking && (
																<Link href={order.shipment_tracking} target="_blank">
																	{order.shipment_tracking}
																</Link>
															)
														}
													</Typography>
												</Grid>	
											</Grid>
										)}
									</Grid> 
								</Paper>
							</Grid>

						)

					}
				</Grid>
			</div>
		</Dialog>
	);
}

ViewOrderDialog.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};