import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Typography, IconButton, TextField, Fab } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { BootstrapTooltip } from "../Utils";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddVendorDialog from "./AddVendorDialog";
import { MasterService } from "../Services/MasterService";

const useStyles = makeStyles(theme => ({
    page: {
        width: '100%',
        marginTop: 16
    },
    extendedIcon: {
        position: 'absolute',
        right: 30,
        bottom: 30,
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function ShipmentVendor(props){

    const { setTitle, setLinearLoading, showConfirmDialog, closeConfirmDialog, showSnackBar, showProgressDialog, closeProgressDialog } = useContext(AppContext);
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [showEditOpen, setShowEditOpen] = React.useState(false);
    const [selectedVendor, setSelectedVendor] = React.useState(null)

    React.useEffect(() => {
        setTitle('Manage Shipment Vendors');
        setGenerate(true);
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setGenerate(false);
            setLinearLoading(true);
            MasterService.getShipmentVendors()
            .then(data => {
                console.log(data);
                setData(data)
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
        }
    }, [generate]);

    const edit = (id, name, template) => {
        setShowEditOpen(true)
        setSelectedVendor({id, name, template})
    }

    const handleChange = (e) => {
        e.persist()
        setSelectedVendor(type => ({...type, [e.target.name]: e.target.value}))
    }
    
    const handleClose = () => {
        setShowEditOpen(false)
        setSelectedVendor(null)
    }

    const clear = (id) => {
        showConfirmDialog('Confirm Reset?', undefined, () => {
            closeConfirmDialog()
            showProgressDialog()
            
            MasterService.removeShipmentVendor(id)
            .then(data => {
                showSnackBar('Resetted Successfully.', "info");
                handleClose()
                setGenerate(true)
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Something went wrong. Please try again later', "error");
                closeProgressDialog();
            })
        })
    }
    
    const handleSave = () => {
        if(isValid()){
            showConfirmDialog('Confirm Save', undefined, () => {
                closeConfirmDialog()
                showProgressDialog()
                let body = {
                    id : selectedVendor.id || null,
                    name : selectedVendor.name || null,
                    template : selectedVendor.template || null,
                }
                MasterService.createShipmentVendor(body)
                .then(data => {
                    if(data.success){
                        showSnackBar('Updated Successfully.', "info");
                        handleClose()
                        setGenerate(true)
                        closeProgressDialog();
                    } else {
                        showSnackBar('Something went wrong. Please try again.', "error");
                        closeProgressDialog();
                    }
                })
                .catch(error => {
                    console.log(error);
                    showSnackBar('Something went wrong. Please try again later', "error");
                    closeProgressDialog();
                })
            })
        } else {
            showSnackBar('Plese fill all the fields with valid values')
        }
    }

    const isValid = () => {
        if(!selectedVendor.name){
            return false
        }
        return true;
    }

    return (
         <div className={classes.page}>
            <div className="lander" >
                <Grid container direction="row" spacing={1} justify="flex-start">
                    
                       {
                            data.map((vendor, i) => {
                                return (
                                    <Grid key={i} item xl={4} lg={4} xs={12}>
                                        <Row  {...vendor} edit={edit} clear={clear}/>
                                    </Grid>
                                )
                            })
                       } 
                    
                </Grid>
            </div>
            <AddVendorDialog
                maxWidth={"sm"}
                content={
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    label="Vendor Name"
                                    placeholder="Vendor Name"
                                    name="name"
                                    value={selectedVendor ? selectedVendor.name || '' : ''}
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    style={{marginLeft: 8, paddingRight: 10}}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputProps: { min: 1, maxLength: 200 }
                                    }}
                                    helperText={`${(selectedVendor && selectedVendor.name ? selectedVendor.name.length : 0)} / 50`}
                                    required
                                    fullWidth
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Tracking URL Template"
                                    placeholder="Template"
                                    name="template"
                                    value={selectedVendor ? selectedVendor.template || '' : ''}
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    style={{marginLeft: 8, paddingRight: 10}}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputProps: { min: 1, maxLength: 200 }
                                    }}
                                    helperText={`Use can use {id} as a placeholder to provide the dynamic tracking ID in a URL`}
                                    required
                                    fullWidth
                                    />
                            </Grid>
                        </Grid>
                    }
                title={''}
                open={showEditOpen}
                save={handleSave}
                close={handleClose}/>
            <Fab variant="extended" color="primary" className={classes.extendedIcon} onClick={() => edit(null)}>
                <AddIcon className={classes.eIcon} />
                    Add Vendor
            </Fab>
        </div>
    );
}


const Row = (props) => {
    const classes = useStyles();
    const {id, name, template, edit, clear} = props;

    return (
        <Paper className={classes.section}>
            <div style={{minHeight: 90, marginTop: 8, marginBottom: 8, marginLeft: 8}}>
                <div style={{float: 'left', width: '90%', height: '100%'}}>
                    <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
                        <Grid item container xs={10} justify="flex-start">
                            <Grid item xs={3}>
                                <Typography variant="body1" align="left">
                                    Vendor:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1" align="left">
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1" align="left">
                                    Tracking URL:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1" align="left">
                                    {template || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{float: 'right', width: '10%', height: '100%'}}>
                    <BootstrapTooltip title="Edit" arrow>
                        <IconButton size="small" style={{marginRight: 8}} onClick={() => edit(id, name, template)}>
                            <EditIcon style={{fontSize: 20, padding: 0}}/>
                        </IconButton>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Clear" arrow>
                        <IconButton size="small" style={{marginRight: 8}} onClick={() => clear(id)}>
                            <DeleteIcon style={{fontSize: 20, padding: 0}}/>
                        </IconButton>
                    </BootstrapTooltip>
                </div>
            </div>
        </Paper>
    )
}