import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, IconButton } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, format_without_time, format_display_fancy_notime, BootstrapTooltip } from "../Utils";
import moment from "moment";
import { CustomerService } from "../Services/CustomerService";
import SearchBar from "../components/SearchBar";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function Customer(props){
    const { setTitle, setLinearLoading, showSnackBar } = useContext(AppContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const columns = [
        { id: 'sl_no', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Name', sortable: true },
        { id: 'mobile', align: 'left', numeric: false, disablePadding: true, label: 'Mobile', sortable: true },
        { id: 'email', align: 'left', numeric: false, disablePadding: true, label: 'Email', sortable: true },
        { id: 'dob', align: 'left', numeric: false, disablePadding: true, label: 'Date of Birth', sortable: true },
        { id: 'address', align: 'left', numeric: false, disablePadding: true, label: 'Address', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.customer_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.customer_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.customer_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('View Customers');
        setGenerate(true);
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setGenerate(false);
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // showProgressDialog();
            let fc = filter && filter.length > 0 ? filter : '';
            CustomerService.getCustomersCount(fc)
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                    // closeProgressDialog();
                } else {
                    setFetchPage(true);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
        }
    }, [generate]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            let fc = filter && filter.length > 0 ? filter : '';
            CustomerService.getCustomers(fc, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                if(data){
                    data.forEach((element, index) => {
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover
                                style={{cursor: 'pointer'}}>
                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 130}}>
                                    {index+1}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {element.name || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {element.mobile || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {element.email || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {element.dob ? moment(element.dob, format_without_time).format(format_display_fancy_notime) : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {element.address1 + ', ' + (element.address2 ? element.address2 : '') + ', ' + (element.address3 ? element.address3 : '') + ', ' + (element.state ? element.state : '') + ', ' + element.pincode}
                                </StyledTableCell>
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                // closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                // closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportData = () => {
        setLinearLoading(true);
        let fc = filter && filter.length > 0 ? filter : '';
        CustomerService.getCustomersExport(fc, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Customers.xlsx');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            showSnackBar('Something went wrong. Please try again', 'error')
        })
        .finally(() => {
            setLinearLoading(false);
        })
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={11} lg={11}>
                                    <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                        <SearchBar 
                                            className={classes.searchBar}
                                            hint="Search..."
                                            searchText={filter}
                                            onTextChange={(e) => {
                                                setFilter(e.target.value);
                                                setGenerate(true);
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <BootstrapTooltip arrow title={totalItems === 0 ? "" : "Download"}>
                                        <IconButton size="medium" disabled={totalItems === 0} style={{marginTop: 8}} aria-label="Refresh" color="inherit" onClick={exportData}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Customers</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}