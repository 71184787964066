import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PeopleIcon from '@material-ui/icons/People';
import {
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Container,
  List,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import { AppContext } from './AppContextProvider';
import { ROLES } from './Roles';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ListIcon from '@material-ui/icons/List';
import AssessmentIcon from '@material-ui/icons/Assessment';
import theme from './theme';
import PersonIcon from '@material-ui/icons/Person';
import MessageIcon from '@material-ui/icons/Message';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
      sectionDesktop: {
        display: 'flex',
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      menuIcon: {
        minWidth: 32
      },
      subMenuTitle: {
        // paddingLeft: 32, 
        paddingTop: 0, 
        paddingBottom: 0
      },
      subMenuItem: {
        color: 'black',
        // paddingTop: 0, 
        // paddingBottom: 0
      },
      nested: {
        paddingLeft: 48, 
        // color: 'black',
        paddingTop: 0, 
        paddingBottom: 0,
      },
      link: {
        textDecoration: 'none !important', 
        color: 'black' 
      },
}));

export default function AppDrawer(props) {

    const classes = useStyles();
    const {state, handleDrawerClose, openCreateOrder} = useContext(AppContext);
    const [openDrawer, setOpenDrawer] = React.useState({
        orders: false,
        accounts: false,
        masters: false,
        logs: false,
    })

    const handleClick = (type) => {
        setOpenDrawer(openDrawer => ({...openDrawer, [type]: !openDrawer[type]}))
    }

    return (
        <Drawer
            anchor="left"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: state.open,
                [classes.drawerClose]: !state.open,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,
                }),
            }}
            open={state.open}
            onClose={handleDrawerClose}
        >
        {(state.user.roles.includes(ROLES.ADMIN) || state.user.roles.includes(ROLES.SUPER_ADMIN))  &&
            <div style={{height: '100vh', overflowY: 'auto'}}>
                <div className={classes.toolbar}>
                    <div style={{ float: "left", width: "100%", marginLeft: 2 }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/icon.png"}
                            alt="icon"
                            width={80}
                        />
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                        {state.isAuthenticated && (
                            <section className={classes.sectionDesktop}>
                                {state.user.name}
                            </section>
                        )}
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div style={{ height: "83vh", overflow: "auto" }}>
                    <Divider />
                    <Link
                        to="/"
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={handleDrawerClose}
                    >
                        <ListItem button key="Dashboard">
                            <ListItemIcon className={classes.menuIcon}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="subtitle2">Dashboard</Typography>}
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/manage/customer" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="View Customers">
                            <ListItemIcon className={classes.menuIcon}>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        View Customers
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/manage/products" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="Manage Products">
                            <ListItemIcon className={classes.menuIcon}>
                                <ShoppingCartOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        Manage Products
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/manage/profile" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="Manage Profile">
                            <ListItemIcon className={classes.menuIcon}>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        Manage Profile
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/manage/messages" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="Manage Messages">
                            <ListItemIcon className={classes.menuIcon}>
                                <MessageIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        Manage Messages
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/view/qr/scans" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                        <ListItem button key="View QR Scans">
                            <ListItemIcon className={classes.menuIcon}>
                                <CropFreeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        View QR Scans
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    {
                        (state.user.roles.includes(ROLES.ADMIN)) &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("logs")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <ListIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                View Logs
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["logs"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["logs"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    > 
                                        <Link to="/view/logs/sms" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose} className={classes.link}>
                                            <ListItem button key="View SMS logs" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            View SMS logs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/view/logs/wa" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose} className={classes.link}>
                                            <ListItem button key="View Whatsapp logs" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            View Whatsapp logs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/view/logs/email" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose} className={classes.link}>
                                            <ListItem button key="View Email logs" className={classes.nested}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            View Email logs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                    {
                        (state.user.roles.includes(ROLES.SUPER_ADMIN) || (state.user.roles.includes(ROLES.ADMIN))) &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("orders")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <AssessmentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Orders
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["orders"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["orders"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        {
                                            (state.user.roles.includes(ROLES.ADMIN)) &&
                                            <ListItem 
                                                button 
                                                key="Create Order" 
                                                onClick={(e) => {
                                                    handleDrawerClose();
                                                    openCreateOrder();
                                                }}
                                                className={classes.nested}>
                                                {/* <ListItemIcon className={classes.menuIcon}>
                                                    <ShoppingBasketIcon />
                                                </ListItemIcon> */}
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Create Order
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        }
                                        {
                                            (state.user.roles.includes(ROLES.ADMIN)) &&
                                            <Link to="/view/orders" className={classes.link}>
                                                <ListItem button key="View Orders" className={classes.nested}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="subtitle2">View Orders</Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </Link>
                                        }
                                        {
                                            (state.user.roles.includes(ROLES.SUPER_ADMIN)) && 
                                            <Link to="/manage/client/orders" className={classes.link}>
                                                <ListItem button key="Manage Client Orders" className={classes.nested}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="subtitle2">Manage Client Orders</Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </Link>
                                        }
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                    {
                        state.user.roles.includes(ROLES.SUPER_ADMIN)  &&
                        <div>
                            <List className={classes.subMenuTitle}>
                                <ListItem button onClick={(e) => handleClick("masters")}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className="sub-menu"
                                            >
                                                Masters
                                            </Typography>
                                        }
                                    />
                                    {openDrawer["masters"] ? (
                                        <ExpandLess />
                                    ) : (
                                            <ExpandMore />
                                        )}
                                </ListItem>
                                <Collapse
                                    in={openDrawer["masters"]}
                                    timeout="auto"
                                    unmountOnExit
                                    className={classes.subMenuItem}
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        onClick={handleDrawerClose}
                                    >
                                        <Link to="/manage/shipment/vendors" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Shipment Vendors"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Shipment Vendors
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to="/manage/config/moq" className={classes.link}>
                                            <ListItem
                                                button
                                                key="Manage Configuration"
                                                className={classes.nested}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            Manage Configuration
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </div>
                    }
                </div>
            </div>
            }
                <div>
                    <Container maxWidth="sm" style={{ position: "absolute", bottom: 0, height: 60, paddingBottom: 10, paddingTop: 10, backgroundColor: theme.palette.primary.main, color: theme.palette.common.white}}>
                        <Typography variant="subtitle2" style={{ textAlign: "center", fontSize: 10 }} color="inherit">
                            Version: {global.appVersion}
                            <React.Fragment>
                                <br />
                                    Powered by Eauthenticity
                            </React.Fragment>
                        </Typography>
                    </Container>
                </div>
        </Drawer>
    );
}
