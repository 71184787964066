import Config from '../Config';
import { RestService } from './RestService';

export const MessageService = {
    getMessagesCount: async function(filter){
        return RestService.get(`${Config.message_list_url}?count=true${ filter && filter.length > 0 ? `&filter=${filter}` : '' }`, {})
        .then(response => response.json());
    },
    getMessages: async function(filter, sort, direction, start, offset){
        return RestService.get(`${Config.message_list_url}?${ filter && filter.length > 0 ? `filter=${filter}` : '' }&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.json());
    },
    remove: async function(headers = {}, id){
        return RestService.delete(Config.message_id_url.replace('{id}', id), headers);
    },
    createProduct: async function(headers, body){
        return RestService.post(Config.message_url, headers, body)
        .then(response => response.json());
    },
};