import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import {
  makeStyles,
  Grid,
  Paper,
  Table,
  TablePagination,
  TableRow,
  TableBody,
  Typography,
  Divider,
  Icon,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { STATUS, TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import {
  toInt,
  StyledTableCell,
  formatNumber,
  getTimeInIST,
  format_display_fancy_notime,
  getStatusBadge,
  titleCase,
  BootstrapTooltip,
} from "../Utils";
import moment from "moment";
import SingleDate from "../components/SingleDate";
import SearchBar from "../components/SearchBar";
import ItemSelection from "../components/ItemSelection";
import { UserService } from "../Services/UserService";
import { OrdersService } from "../Services/OrdersService";
import { ROLES } from "../Roles";

const useStyles = makeStyles((theme) => ({
  section: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  qtyField: {
    width: "40%",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      right: "100px",
      width: "10%",
      marginRight: 10,
    },
    right: "22px",
  },
  searchBar: {
    maxWidth: 180,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: "20px",
  },
  grid: {
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
    marginTop: "50px",
    marginBottom: "5px",
  },
  icon: {
    padding: "0px",
    [theme.breakpoints.up("md")]: {
      padding: "12px",
    },
  },
  tablecell: {
    fontSize: "16pt",
    padding: theme.spacing(4),
  },
  container: {
    paddingTop: theme.spacing(1),
  },
  redAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: red[500],
  },
  blueAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: blue[500],
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    // height: theme.spacing(7),
    float: "right",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      float: "inherit",
      marginLeft: theme.spacing(1),
    },
    marginRight: theme.spacing(4),
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto",
  },
  list: {
    width: "100%",
    height: 250,
    overflow: "auto",
  },
  summary: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 18,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectRoot: {
    fontSize: 12,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  completed: {
    color: `${green[500]} !important`,
  },
}));

export default function ViewClientOrders(props) {
  const {
    state,
    setTitle,
    setLinearLoading,
    showConfirmDialog,
    closeConfirmDialog,
    showProgressDialog,
    closeProgressDialog,
    openViewOrder,
    openUpdateShipmentInfo,
    showSnackBar,
    openUpdatePaymentInfo,
  } = useContext(AppContext);
  const classes = useStyles();
  const [clients, setClients] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedStatuses, setSelectedStatuses] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [startdate, setStartDate] = React.useState(
    moment().add(-1, "month").startOf("day").startOf("month")
  );
  const [enddate, setEndDate] = React.useState(moment().endOf("day"));
  const statuses = Object.keys(STATUS).map((key) => ({
    name: titleCase(key),
    value: STATUS[key],
  }));
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_date");
  const [orders, setOrders] = React.useState([]);
  const [generate, setGenerate] = React.useState(true);
  const [loadedMaster, setLoadedMaster] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const columns = [
    {
      id: "index",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Sl.No.",
      sortable: false,
    },
    {
      id: "order_no",
      align: "justify",
      numeric: false,
      disablePadding: false,
      label: "Order No",
      sortable: true,
    },
    {
      id: "client_name",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Client Name",
      sortable: true,
    },
    {
      id: "order_date",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Order Date",
      sortable: true,
    },
    {
      id: "amount",
      align: "center",
      numeric: true,
      disablePadding: true,
      label: "Amount",
      sortable: true,
    },
    {
      id: "payment_date",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Paid On",
      sortable: true,
    },
    {
      id: "shipment_date",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Shipped Date",
      sortable: true,
    },
    {
      id: "status",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Status",
      sortable: true,
    },
    {
      id: "action",
      align: "right",
      numeric: true,
      disablePadding: false,
      label: "",
      sortable: false,
    },
  ];

  //Pagination
  const [totalItems, setTotalItems] = React.useState(0);
  const [numItemsPerPage, setNumItemsPerPage] = React.useState(
    localStorage.getItem(TABLE_COUNTS.orders_tab_count)
      ? toInt(localStorage.getItem(TABLE_COUNTS.orders_tab_count))
      : RowCountOptions[0]
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [fetchPage, setFetchPage] = React.useState(false);

  const handleChangeRowsPerPage = (event) => {
    setNumItemsPerPage(event.target.value);
    setCurrentPageNumber(0);
    setFetchPage(true);
    localStorage.setItem(TABLE_COUNTS.orders_tab_count, event.target.value);
  };

  const handleChangePage = (event, page) => {
    if (currentPageNumber > page) {
      fetchRow(currentPageNumber - 1);
    } else {
      fetchRow(currentPageNumber + 1);
    }
  };

  const fetchRow = (newCurrentPage) => {
    setCurrentPageNumber(newCurrentPage);
    setFetchPage(true);
  };

  React.useEffect(() => {
    setTitle("View Client orders");
    showProgressDialog();
    // console.log(state);
    UserService.getClientList(true).then((data) => {
      // console.log(data);
      setClients(data);
      closeProgressDialog();
      setLoadedMaster(true);
    });
    return () => {
      setTitle("");
    };
  }, [true]);

  // React.useEffect(() => {
  //     if(loadedMaster){
  //         closeProgressDialog();
  //     }
  // }, [loadedMaster]);

  React.useEffect(() => {
    // console.log(generate, loadedMaster)
    if (generate && loadedMaster) {
      setLinearLoading(true);
      // setToolTitle(undefined);
      if (initialLoading) {
        setCurrentPageNumber(0);
        setTotalItems(0);
        showProgressDialog();
        setInitialLoading(false);
      }
      let client_id =
        selectedClient && selectedClient.id ? selectedClient.id : null;
      let selectedStatusId =
        selectedStatuses && selectedStatuses.length > 0
          ? selectedStatuses.map((s) => s.value).join(",")
          : null;
      let fc = filter && filter.length > 0 ? filter : "";
      OrdersService.getOrdersCount(
        startdate,
        enddate,
        fc,
        client_id,
        selectedStatusId
      )
        .then((data) => {
          console.log(data);
          setTotalItems(toInt(data.count));
          setFetchPage(true);
          if (toInt(data.count) === 0) {
            setOrders([]);
          }
          setLinearLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLinearLoading(false);
        });
      setGenerate(false);
    }
  }, [generate, loadedMaster]);

  const openDialog = (orderNo) => {
    openViewOrder(orderNo, (shouldRefresh) => {
      if (shouldRefresh) {
        setGenerate(true);
      }
    });
  };

  const handleUpdateShipmentInfo = (order) => {
    console.log(order);
    openUpdateShipmentInfo(order.id, () => {
      setGenerate(true);
    });
  };

  const handleUpdatePaymentInfo = (order) => {
    console.log(order);
    openUpdatePaymentInfo(order.id, () => {
      setGenerate(true);
    });
  };

  const handleMarkCompleted = (order) => {
    console.log(order);
    showConfirmDialog("Confirm mark completed?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Marking Completed");
      OrdersService.updateStatus(order.id, 5)
        .then((data) => {
          if (data.success) {
            showSnackBar("Marked Completed", "info");
            setGenerate(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showSnackBar("Something went wrong. Please try again.", "error");
        })
        .finally(() => closeProgressDialog());
    });
  };

  const handleMarkInProgress = (order) => {
    console.log(order);
    if(order.exported === 1){
      showConfirmDialog("Confirm mark in-progress?", undefined, () => {
        closeConfirmDialog();
        showProgressDialog("Marking In-Progress");
        OrdersService.updateStatus(order.id, 2)
          .then((data) => {
            if (data.success) {
              showSnackBar("Marked In-progress", "info");
              setGenerate(true);
            } else {
              showSnackBar("Something went wrong. Please try again.", "error");
            }
          })
          .catch((error) => {
            console.log(error);
            showSnackBar("Something went wrong. Please try again.", "error");
          })
          .finally(() => closeProgressDialog());
      });
    } else {
      showSnackBar("Export the order to mark it In-Progress", "info");
    }
  };
  
  const handleMarkPrinted = (order) => {
    console.log(order);
    showConfirmDialog("Confirm mark printed?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Marking Printed");
      OrdersService.updateStatus(order.id, 3)
        .then((data) => {
          if (data.success) {
            showSnackBar("Marked Printed", "info");
            setGenerate(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showSnackBar("Something went wrong. Please try again.", "error");
        })
        .finally(() => closeProgressDialog());
    });
  };

  // const cancel = (pledgeNo) => {
  // 	showProgressDialog('Cancelling Loan...');
  // 	LoanRequestsService.cancelRequest(pledgeNo)
  // 	.then(data => {
  //         showSnackBar('Loan Cancelled', 'info');
  //         closeProgressDialog();
  //         setGenerate(true);
  // 	})
  // 	.catch(error => {
  // 		console.log(error);
  // 		showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
  // 			closeConfirmDialog();
  // 		});
  // 		closeProgressDialog();
  // 	})
  // }

  // const handleCancelLoan = (pledgeNo) => {
  //     console.log(pledgeNo);
  //     showConfirmDialog(`Confirm Cancel Loan #${pledgeNo} ?`, undefined, () => {
  //         closeConfirmDialog();
  //         cancel(pledgeNo);
  //         // FinanceService.removeTransaction(loan, id)
  //         // .then(() => {
  //         //     showSnackBar(`Removed Tranaction`, 'success');
  //         //     setGenerate(true);
  //         // })
  //         // .catch((error) => {
  //         //     console.log(error);
  //         //     showSnackBar(`Failed to remove. Please try again or Contact Admin`, 'error');
  //         // })
  //     });
  // }

  React.useEffect(() => {
    if (fetchPage) {
      setLinearLoading(true);
      let client_id =
        selectedClient && selectedClient.id ? selectedClient.id : null;
      let selectedStatusId =
        selectedStatuses && selectedStatuses.length > 0
          ? selectedStatuses.map((s) => s.value).join(",")
          : null;
      let fc = filter && filter.length > 0 ? filter : "";
      OrdersService.getOrders(
        startdate,
        enddate,
        fc,
        client_id,
        selectedStatusId,
        orderBy,
        order,
        currentPageNumber * numItemsPerPage,
        numItemsPerPage
      )
        .then((data) => {
          console.log(data);
          setOrders(data || []);
          setLinearLoading(false);
          closeProgressDialog();
        })
        .catch((error) => {
          console.log(error);
          setLinearLoading(false);
          closeProgressDialog();
        });
    }
    setFetchPage(false);
  }, [fetchPage]);

  const sortBy = (event, property) => {
    let o = "desc";
    if (orderBy === property && order === "desc") {
      o = "asc";
    }
    setOrder(o);
    setOrderBy(property);
    setGenerate(true);
  };

  const exportData = (element) => {
    setLinearLoading(true);
    OrdersService.getOrderExport(element.id)
    .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Order ${element.order_no}-${element.client_name.replace(/\s+/g, '').substring(0,8)}.zip`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        let o = orders.find(o => o.id === element.id)
        o.exported = 1
        setOrders(orders => ([...orders.filter(o => o.id !== element.id), o]))
    })
    .catch(error => {
        showSnackBar('Something went wrong. Please try again', 'error')
    })
    .finally(() => {
        setLinearLoading(false);
    })
  }


  return (
    <div className="Home">
      <div className="lander">
        <Grid container direction="row" spacing={1} justify="center">
          <Grid item xl={11} lg={12} xs={12}>
            <Paper className={classes.section}>
              <Grid container spacin={1} alignItems="center">
                <Grid item xs={12} lg={2}>
                  <ItemSelection
                    clearable={true}
                    label="Clients"
                    value={selectedClient}
                    optionLabel="name"
                    options={clients}
                    selected={setSelectedClient}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <ItemSelection
                    multiple
                    clearable={true}
                    label="Status"
                    value={selectedStatuses}
                    optionLabel="name"
                    options={statuses}
                    selected={setSelectedStatuses}
                  />
                </Grid>
                <Grid item>
                  <div style={{ maxWidth: 170 }}>
                    <SingleDate
                      label="From"
                      date={startdate}
                      maxDate={moment()}
                      setDate={(date) => {
                        setStartDate(date);
                      }}
                      onKeyUp={(event) => {
                        event.preventDefault();
                        if (event.key === "Enter") {
                          !generate && setGenerate(true);
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ maxWidth: 170, marginLeft: 8 }}>
                    <SingleDate
                      label="To"
                      date={enddate}
                      minDate={startdate}
                      maxDate={moment()}
                      setDate={(date) => {
                        setEndDate(date);
                      }}
                      onKeyUp={(event) => {
                        event.preventDefault();
                        if (event.key === "Enter") {
                          !generate && setGenerate(true);
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                  {loadedMaster && (
                    <div
                      style={{ float: "right", marginTop: 8, marginRight: 8 }}
                    >
                      <SearchBar
                        className={classes.searchBar}
                        hint="Search..."
                        searchText={filter}
                        onTextChange={(e) => {
                          setFilter(e.target.value);
                          setGenerate(true);
                        }}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item>
                  <Tooltip title="Refresh">
                    <IconButton
                      size="medium"
                      style={{ marginTop: 8 }}
                      aria-label="Refresh"
                      color="primary"
                      onClick={() => {
                        !generate && setGenerate(true);
                      }}
                    >
                      <Icon>refresh</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div
                    style={{
                      minHeight: 100,
                      overflowY: "auto",
                      display: "block",
                    }}
                  >
                    <Table className={classes.table} stickyHeader>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={sortBy}
                        rows={columns}
                      />
                      <TableBody>
                        {orders &&
                          orders.map((element, index) => {
                            return (
                              <TableRow
                                key={index}
                                hover
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDialog(element.id);
                                }}
                              >
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  padding="none"
                                  style={{ width: 20 }}
                                >
                                  {index +
                                    1 +
                                    currentPageNumber * numItemsPerPage}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="justify"
                                  padding="none"
                                  style={{ maxWidth: "12vw" }}
                                >
                                  {element.order_no || ""}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  padding="none"
                                >
                                  {element.client_name || "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  padding="none"
                                  style={{ maxWidth: "12vw" }}
                                >
                                  {element.order_date
                                    ? getTimeInIST(element.order_date).format(
                                        format_display_fancy_notime
                                      )
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="right"
                                  padding="none"
                                  style={{ paddingRight: 16 }}
                                >
                                  {element.amount &&
                                    formatNumber(element.amount)}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  padding="none"
                                  style={{ maxWidth: "12vw" }}
                                >
                                  {element.payment_date
                                    ? getTimeInIST(element.payment_date).format(
                                        format_display_fancy_notime
                                      )
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  padding="none"
                                  style={{ maxWidth: "12vw" }}
                                >
                                  {element.shipment_date
                                    ? getTimeInIST(
                                        element.shipment_date
                                      ).format(format_display_fancy_notime)
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  padding="none"
                                  style={{ paddingRight: 16, maxWidth: "12vw" }}
                                >
                                  {`${element.status}`
                                    ? getStatusBadge(`${element.status}`)
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  scope="row"
                                  align="right"
                                  padding="none"
                                  style={{ paddingRight: 16 }}
                                >
                                  <BootstrapTooltip
                                    arrow
                                    title={
                                      element.status > 0 ? "Paid" : "Mark Paid"
                                    }
                                  >
                                    <span onClick={(e) => e.stopPropagation()}>
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          `${element.status}` !== STATUS.NEW ||
                                          !state.user.roles.includes(
                                            ROLES.SUPER_ADMIN
                                          )
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUpdatePaymentInfo(element);
                                        }}
                                        classes={{
                                          disabled:
                                            element.status > 0
                                              ? classes.completed
                                              : null,
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Icon
                                          fontSize="small"
                                          style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                          }}
                                        >
                                          payment
                                        </Icon>
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    arrow
                                    title={
                                      element.exported === 1 ? "Re-export" : "Export"
                                    }
                                  >
                                    <span onClick={(e) => e.stopPropagation()}>
                                      <IconButton
                                        color="primary"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          exportData(element);
                                        }}
                                        style={{ padding: 0 }}
                                        classes={{
                                          root:
                                            element.exported === 1
                                              ? classes.completed
                                              : null,
                                        }}
                                      >
                                        <Icon
                                          fontSize="small"
                                          style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                          }}
                                        >
                                          save_alt
                                        </Icon>
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    arrow
                                    title={
                                      element.status > 1
                                        ? "In-Progress"
                                        : "Mark In-Progress"
                                    }
                                  >
                                    <span onClick={(e) => e.stopPropagation()}>
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          `${element.status}` !== STATUS.PAID ||
                                          !state.user.roles.includes(
                                            ROLES.SUPER_ADMIN
                                          )
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleMarkInProgress(element);
                                        }}
                                        classes={{
                                          disabled:
                                            element.status > 1
                                              ? classes.completed
                                              : null,
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Icon
                                          fontSize="small"
                                          style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                          }}
                                        >
                                          print
                                        </Icon>
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    arrow
                                    title={
                                      element.status > 2
                                        ? "Printed"
                                        : "Mark Printed"
                                    }
                                  >
                                    <span onClick={(e) => e.stopPropagation()}>
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          `${element.status}` !== STATUS.INPROGRESS ||
                                          !state.user.roles.includes(
                                            ROLES.SUPER_ADMIN
                                          )
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleMarkPrinted(element);
                                        }}
                                        classes={{
                                          disabled:
                                            element.status > 2
                                              ? classes.completed
                                              : null,
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Icon
                                          fontSize="small"
                                          style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                          }}
                                        >
                                          description
                                        </Icon>
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    arrow
                                    title={
                                      element.status > 3
                                        ? "Shipped"
                                        : "Mark Shipped"
                                    }
                                  >
                                    <span onClick={(e) => e.stopPropagation()}>
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          `${element.status}` !==
                                            STATUS.PRINTED ||
                                          !state.user.roles.includes(
                                            ROLES.SUPER_ADMIN
                                          )
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUpdateShipmentInfo(element);
                                        }}
                                        classes={{
                                          disabled:
                                            element.status > 3
                                              ? classes.completed
                                              : null,
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Icon
                                          fontSize="small"
                                          style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                          }}
                                        >
                                          local_shipping
                                        </Icon>
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    arrow
                                    title={
                                      element.status > 4
                                        ? "Completed"
                                        : "Mark Completed"
                                    }
                                  >
                                    <span onClick={(e) => e.stopPropagation()}>
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          `${element.status}` !==
                                            STATUS.SHIPPED ||
                                          !state.user.roles.includes(
                                            ROLES.SUPER_ADMIN
                                          )
                                        }
                                        onClick={(e) => {
                                          console.log("Mark Completed");
                                          e.stopPropagation();
                                          handleMarkCompleted(element);
                                        }}
                                        classes={{
                                          disabled:
                                            element.status > 4
                                              ? classes.completed
                                              : null,
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Icon
                                          fontSize="small"
                                          style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                          }}
                                        >
                                          check_circle
                                        </Icon>
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                  {totalItems === 0 && (
                    <Typography color="error" variant="subtitle2">
                      No Orders
                    </Typography>
                  )}
                  <TablePagination
                    rowsPerPageOptions={RowCountOptions}
                    component="div"
                    count={totalItems}
                    rowsPerPage={numItemsPerPage}
                    page={currentPageNumber}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    classes={{ selectRoot: classes.selectRoot }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
