import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, Button, TextField, Card, CardHeader, CardContent, Divider, colors } from "@material-ui/core";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";
import { MasterService } from "../Services/MasterService";
import { BootstrapTooltip } from "../Utils";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        float: 'right',
        marginBottom: theme.spacing(2)
    },
    addButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        float: 'left',
        marginBottom: theme.spacing(3)
    },
    removeButton: {
        float: 'right',
        marginBottom: theme.spacing(2),
        color: theme.palette.white,
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        },
    },
    actions: {
        float: 'right',
    },
    textField: {
        padding: 0
    }
}));

export default function RateCard(props) {

    const {showConfirmDialog, showSnackBar, showProgressDialog, closeProgressDialog, closeConfirmDialog} = useContext(AppContext);
    const classes = useStyles();

    const [data, setData] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [newRowRrrors, setNewRowErrors] = React.useState({id: 9999, unit: false, value: false});

    const [newRow, setNewRow] = React.useState({id: 9999, unit: 0, value: 0});

    React.useEffect(() => {
       fetchData();
    }, [true]);

    const fetchData = () => {
        MasterService.getRateCard()
        .then(data => {
            console.log(data)
            setData(data)
            setErrors(data.map(d => ({id: d.id, unit: false, value: false})))
        })
    }

    const handleChange = (id) => e => {
        e.persist();
        let name = e.target.name;
		let value = e.target.value;
        if(id !== 9999){
            setData(data.map(row => 
                row.id === id
                ?
                    {...row, [name]: value}
                :	
                    row
                )
            );
            setErrors(rows => rows.map(row => 
                row.id === id
                ?
                {...row, [name]: !value}
                :row
            ));
        } else {
            setNewRow(row => ({...row, [name]: value}))
            setNewRowErrors(row => ({...row, [name]: !value}));
        }
        
    }

    const addNewRow = () => {
        saveRow(newRow)
    }

    const saveRow = (row) => {
        if(row.unit && row.id && row.value){
            showProgressDialog('Save...')
            let body = [{
                id: row.id !== 9999 ? row.id : null,
                type: 'rate',
                unit: row.unit,
                value: row.value,
            }];
            MasterService.updateConfig(body)
            .then(data => {
                if(data.success){
                    showSnackBar("Saved!", "success");
                    fetchData();
                    setNewRowErrors({id: 9999, unit: false, value: false});
                    setNewRow({id: 9999, unit: 0, value: 0});
                } else {
                    showSnackBar("Something went wrong. Try again later.", "error");
                }
            }).catch((error) => {
                showSnackBar("Something went wrong. Try again later.", "error");
            })
            .finally(() => closeProgressDialog());
        } else {
            if(row.id === 9999) {
                setNewRowErrors(r => ({...r, value: !row.value, unit: !row.unit}));
            }
        }
    }

    const remove = (rowId) => {
        showConfirmDialog('Confirm remove?', undefined, () => {
            closeConfirmDialog()
            showProgressDialog('Removing...')
            MasterService.removeConfig(rowId)
            .then(() => {
                showSnackBar("Removed", "success");
                fetchData();
            }).catch((error) => {
                showSnackBar("Something went wrong. Try again later.", "error");
            })
            .finally(() => closeProgressDialog());
        })
    }

    return (
        <div>
            <Card className={clsx(classes.root)}>
                <CardHeader 
                    title="Manage Rate Cards"
                    titleTypographyProps={{align: 'left'}} />
                <Divider />
                <CardContent>
                    <Grid container spacing={4} alignItems="center" justify="center">
                        {
                            data.length > 0 && errors.length > 0 && data.map((row, i) => {
                                let eRow = errors.find(i => i.id === row.id);
                                return (
                                    <Grid key={i} item container spacing={1} alignItems="center" justify="flex-start">
                                        <Grid item xs={12} lg={3}>
                                            <FormControl fullWidth className={classes.textField}>
                                                <TextField
                                                    label="Quantity"
                                                    type="number"
                                                    required
                                                    name="unit"
                                                    error={eRow ? eRow.unit : false}
                                                    helperText={row.unit === '' ? 'Cannot be empty' : ' '}
                                                    margin="none"
                                                    variant="outlined"
                                                    inputProps={{
                                                        min: 0,
                                                        style: { textAlign: 'right' }
                                                    }}
                                                    onChange={handleChange(row.id)}
                                                    defaultValue={row.unit}
                                                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Rate"
                                                    className={classes.textField}
                                                    type="number"
                                                    required
                                                    name="value"
                                                    error={eRow ? eRow.value : false}
                                                    helperText={row.value === '' ? 'Cannot be empty' : ' '}
                                                    margin="none"
                                                    variant="outlined"
                                                    inputProps={{
                                                        min: 0,
                                                        style: { textAlign: 'right' }
                                                    }}
                                                    onChange={handleChange(row.id)}
                                                    defaultValue={row.value}
                                                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="contained" className={classes.saveButton} onClick={() => saveRow(row)}>
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <BootstrapTooltip title="Remove" arrow>
                                                <Button startIcon={<DeleteIcon />} variant="contained" className={classes.removeButton} onClick={() => row && remove(row.id)}>
                                                    Remove
                                                </Button>
                                            </BootstrapTooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid item container spacing={1} alignItems="center" justify="flex-start" style={{marginTop: 16}}>
                        <Grid item xs={12} lg={3}>
                            <FormControl fullWidth className={classes.textField}>
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    required
                                    name="unit"
                                    error={newRowRrrors.unit || false}
                                    helperText={newRow.unit === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    variant="outlined"
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }
                                    }}
                                    onChange={handleChange(newRow.id)}
                                    value={newRow.unit}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Rate"
                                    className={classes.textField}
                                    type="number"
                                    required
                                    error={newRowRrrors.value || false}
                                    name="value"
                                    helperText={newRow.value === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    variant="outlined"
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }
                                    }}
                                    onChange={handleChange(newRow.id)}
                                    value={newRow.value}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <BootstrapTooltip title="Add" arrow>
                                <Button variant="contained" className={classes.addButton} onClick={() => addNewRow()}>
                                    Add New Rate
                                </Button>
                            </BootstrapTooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
 