import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Card, CardContent, colors } from "@material-ui/core";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";
import { MasterService } from "../Services/MasterService";
import { toInt } from "../Utils";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        marginTop: theme.spacing(1),
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        float: 'right',
    },
    actions: {
        float: 'right',
    }
}));

export default function MOQConfig(props) {
    const classes = useStyles();
    const {showConfirmDialog, showSnackBar, closeConfirmDialog, setLinearLoading} = useContext(AppContext);

    const [moq, setMOQ] = React.useState(10);
    const [multiples, setMultiples] = React.useState(1);
    const [data, setData] = React.useState(null);
    const [multiplesData, setMultiplesData] = React.useState({});
    const [errorMOQ, setErrorMOQ] = React.useState(false);
    const [errorMultiplesOf, setErrorMultiplesOf] = React.useState(false);

    React.useEffect(() => {
        Promise.all([MasterService.getMOQ(), MasterService.getConfig('multiples')])
        .then(datas => {
            console.log(data)
            if(datas[0]){
                setData(datas[0])
                setMOQ(datas[0] ? toInt(datas[0].value) : 0);
            }
            if(datas[1]){
                setMultiplesData(datas[1])
                setMultiples(datas[1] ? toInt(datas[1].value) : 1)
            }
        })
    }, [true]);

    const handleChange = (e) => {
        e.persist()
        let type = e.target.name;
        let value = e.target.value;

        switch(type){
            case 'name': {
                if(value.length === 0 || isNaN(value)){
                    setErrorMOQ(true); 
                } else {
                    setErrorMOQ(false);
                    setMOQ(value);
                }
                break;
            }
            case 'multiples': {
                if(value.length === 0 || isNaN(value) || value < 2){
                    setErrorMultiplesOf(true); 
                } else {
                    setErrorMultiplesOf(false);
                    setMultiples(value);
                }
                break;
            }
            default:;
        }
    }
    
    const submitChanges = () => {
        if(!errorMOQ){
            showConfirmDialog("Confirm changes? ", undefined, () => {
                confirmChange();
            }, "Cancel", "Yes");
        }
    }

    const confirmChange = () => {
        let body = [{
            id: data.id,
            type: data.type,
            value: moq,
            unit: data.unit
        }, {
            id: multiplesData.id,
            type: multiplesData.type,
            value: multiples,
            unit: multiplesData.unit
        }];
        setLinearLoading(true);
        MasterService.updateConfig(body)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar("Saved", "success");
            } else {
                showSnackBar("Something went wrong. Try again later.", "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    return (
        <div >
            <Grid container direction="row" spacing={4} justify="center">
                <Grid item xl={12} lg={12} xs={12}>
                    <Card className={clsx(classes.root)}>
                        <CardContent>
                            <Grid container justify="space-between" spacing={1}>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        error={errorMOQ}
                                        label="Minimum Order Quantity"
                                        required
                                        name="moq"
                                        className={classes.textField}
                                        type="number"
                                        margin="none"
                                        value={moq}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'right' }
                                        }}
                                        onChange={handleChange}
                                        />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        error={errorMultiplesOf}
                                        label="Multiples Of"
                                        required
                                        name="multiples"
                                        className={classes.textField}
                                        type="number"
                                        margin="none"
                                        value={multiples}
                                        variant="outlined"
                                        inputProps={{
                                            min: 1,
                                            style: { textAlign: 'right' }
                                        }}
                                        onChange={handleChange}
                                        />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Button variant="contained" className={classes.saveButton} color="primary" onClick={submitChanges}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
 