import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { AppContext } from '../AppContextProvider';
import { Grid, TextField } from '@material-ui/core';
import { format } from '../Utils';
import moment from 'moment';
import { OrdersService } from '../Services/OrdersService';
import DateTime from '../components/DateTime';
import { MasterService } from '../Services/MasterService';
import ItemSelection from '../components/ItemSelection';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    dialogContent: {
        paddingTop: 0
    },
    formAutoClass: {
      marginLeft: 0,
      marginRight: 8,
    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{paddingRight: 24}}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UpdateShipmentInfoDialog(props) {
    const {showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
    const {id} = props;
    const [trackingDetails, setTrackingDetails] = React.useState(null);
    const [selectedVendor, setSelectedVendor] = React.useState(null);
    const [shippedOn, setShippedOn] = React.useState(moment());
    const [open, setOpen] = React.useState(false);
    const [shipmentVendors, setShipmentVendors] = React.useState(null);
    const classes = useStyles();

    React.useEffect(() => {
        if(props.open){
            MasterService.getShipmentVendors()
            .then(data => {
                console.log(data);
                setShipmentVendors(data)
                setOpen(props.open);
            })
            .catch(error => {
                console.log(error);
                handleClose();
            });
        } else {
          setOpen(props.open);
        }
    }, [props.open]);

    const handleClose = (callback = false) => {
        if(callback){
          props.onClose(callback);
        }
        setTrackingDetails(null)
        setShippedOn(moment())
        setOpen(false);
    };

    const handleSave = () => {
      if(!trackingDetails || trackingDetails.length === 0){
        showSnackBar(`Please enter tracking details`, 'info')
      } else {
        showConfirmDialog("Confirm mark shipped?", undefined, () => {
            closeConfirmDialog();
            let body = {
              id: id,
              vendor_id: selectedVendor.id,
              tracking: trackingDetails,
              shipment_date: shippedOn ? moment(shippedOn).utc().format(format) : null
            };
            showProgressDialog();
            OrdersService.updateShipmentInfo(body, {})
            .then(data => {
              if(data.success) {
                showSnackBar('Shipment Details Updated!', 'success')
                handleClose(true)
              } else {
                showSnackBar(`Something went wrong while trying to save follow up. Please try again.`, 'error')
              }
              closeProgressDialog();
            })
            .catch(error => {
              console.log(error);
              showSnackBar(`Something went wrong while trying to save follow up. Please try again.`, 'error');
              closeProgressDialog();
            })
        });
      //   props.save(amount, selectedMode.value, selectedLedger, additionalNarration || '', partRepayment || 0)
      }
    }

    const handleTrackingDetailsChange = e => {
      e.preventDefault();
      let value = e.target.value;
      setTrackingDetails(value);
    }

    return (
        <Dialog fullWidth={true} maxWidth={"xs"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
              <Typography variant="h6" component="div">
                Shipment Details
              </Typography>
            </DialogTitle>
            <DialogContent dividers style={{paddingTop: 16}}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <ItemSelection
                    required 
                    label='Shipment Carrier'
                    value={selectedVendor || null} 
                    optionLabel='name'
                    options={shipmentVendors} 
                    style={{zIndex: 500, marginTop: 0}}
                    formClass={classes.formAutoClass}
                    selected={(mode) => {
                      setSelectedVendor(mode);
                    }}/>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Tracking Details"
                    placeholder="Tracking Details"
                    name="tracking"
                    defaultValue={''}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    rows={2}
                    multiline={true}
                    onChange={handleTrackingDetailsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <DateTime 
                    label="Shipment Date"
                    date={shippedOn}
                    maxDate={moment().startOf('day')}
                    setDate={(date) => {
                        setShippedOn(date);
                    }}/>
                </Grid>
              </Grid>
            </DialogContent> 
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button autoFocus onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UpdateShipmentInfoDialog.prototype = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};