import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Grid} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { format_ddmmyyyyhhmm } from "../Utils";
// import { format_display_fancy_notime } from "../Utils";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        // maxWidth: 120,
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            // marginLeft: theme.spacing(1),
        },
    },
}));

export default function DateTime(props){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState(moment().startOf('day'));

    React.useEffect(() => {
        if(props.date) setDate(props.date);
    }, [props]);

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        fullWidth
                        open={open}
                        disabled={props.disabled || false}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        label={props.label || 'Date'}
                        value={date}
                        error={props.error || undefined}
                        helperText={props.helperText}
                        maxDate={props.maxDate || undefined}
                        minDate={props.minDate || undefined}
                        onChange={(date) => {
                            setDate(date);
                            props.setDate(date);
                        }}
                        disableToolbar
                        placeholder="20/01/2020 13:00"
                        format={format_ddmmyyyyhhmm}
                        className={props.classes || classes.formControl}
                        onKeyUp={props.onKeyUp || undefined}
                        
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {/* {
                props.maxDate &&
                <Grid item xs={12} lg={12}>
                    <FormHelperText style={{fontSize: 10, textAlign: 'right'}}>Data available till {moment(props.maxDate).format(format_display_fancy_notime)}</FormHelperText>
                </Grid>
            } */}
        </Grid>
    );
};

DateTime.propTypes = {
    date: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};
  