import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { FormControl,  makeStyles } from '@material-ui/core';
import { ProductService } from '../Services/ProductService';
import { AppContext } from '../AppContextProvider';
import { FIELD_TYPES } from '../Constants';
import ItemSelection from '../components/ItemSelection';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight : 90,
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        // },
    },
  }));

export default function AddCustomField(props) {
    const {showProgressDialog, showSnackBar, closeProgressDialog, closeConfirmDialog, showConfirmDialog} = useContext(AppContext);
    const { open, product, onSuccess, onError, onCancel } = props;
    const classes = useStyles();
    const [type, setType] = React.useState(null);
    const [label, setLabel] = React.useState(null);
    const [value, setValue] = React.useState(null);
    const [error, setError] = React.useState({label: false, type: false, value: false});

    React.useEffect(() => {
        if(open){
            
        }
    
    }, [open]);

    const handleChange = e => {
        if(e.target.name === 'label'){
            setLabel(e.target.value);
            if(e.target.value.length < 3){
                setError(error => ({...error, label: true}));
            } else {
                setError(error => ({...error, label: false}));
            }
        } else if(e.target.name === 'value'){
            setValue(e.target.value);
            if(e.target.value.length < 3){
                setError(error => ({...error, value: true}));
            } else {
                setError(error => ({...error, value: false}));
            }
        }
    };

    

    const AddField = () => {
        showProgressDialog(`Adding Custom field to product ${product.name}...`);
        if(type && value && label){
            showConfirmDialog('Confirm save?', undefined, () => {
                closeConfirmDialog()
                let body = {type: type.key, value, label};
                ProductService.addProductField({}, product.id, [body])
                .then((data) => {
                    if(data.success){
                        showSnackBar('Custom field added.', "info");
                        onSuccess(body);
                        resetForm();
                        closeProgressDialog();
                    } else {
                        showSnackBar('Something went wrong. Please try again.', "error");
                        closeProgressDialog();
                    }
                })
                .catch(error => {
                    console.log(error);
                    onError();
                    showSnackBar('Something went wrong. Please try again later', "error");
                    closeProgressDialog();
                })
            })
        }
    }

    const handleClose = () => {
        resetForm();
        onCancel();
    }

    const resetForm = () => {
        setError({ label: false, type: false, value: false });
        setType(null);
        setLabel(null);
        setValue(null);
    }
    
    return (
        <Dialog fullWidth onClose={handleClose} maxWidth="sm" open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Product</DialogTitle>
        <DialogContent>
            <FormControl fullWidth className={classes.root}>
                <TextField
                    autoFocus
                    label='Label'
                    name='label'
                    type="text"
                    required
                    fullWidth
                    helperText='Enter Field Label'
                    error={error.label}
                    variant="outlined"
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl fullWidth className={classes.root}>
                <TextField
                    label='Value'
                    name='value'
                    type="text"
                    required
                    fullWidth
                    helperText='Enter Field Value'
                    error={error.value}
                    variant="outlined"
                    onChange={handleChange}
                />
            </FormControl>
            <ItemSelection
                required 
                label='Type'
                value={type || null} 
                optionLabel='label'
                options={FIELD_TYPES} 
                style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
                formClass={classes.formAutoClass}
                selected={(type) => {
                    setType(type);
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={AddField} color="primary">
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
}

AddCustomField.propTypes = {
    open: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    product: PropTypes.object,
};