import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, Icon, TextField, Table, TableBody, TableRow } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { formatNumber, StyledTableCell, toInt } from '../Utils';
import { AppContext } from '../AppContextProvider';
import EnhancedTableHead from '../components/EnhancedTableHead';
import { OrdersService } from '../Services/OrdersService';
import { ROLES } from '../Roles';
import ItemSelectionAsync from '../components/ItemSelectionAsync';
import { MasterService } from '../Services/MasterService';
import ItemSelection from '../components/ItemSelection';

let counter = 0;
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	summary: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.black,
		fontWeight: 900,
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	subtitle: {
		// marginLeft: theme.spacing(2),
		flex: 1,
		fontWeight: 600
		// color: theme.palette.white
	},
	formAutoClass: {
		zIndex: 3,
		marginTop: 8
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateOrderRequest(props) {
	const {state, openViewOrder, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D, formatTypes} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [order, setOrder] = React.useState({});
	const [orderItemRows, setOrderItemRows] = React.useState([]);
	const [disableEdit, setDisableEdit] = React.useState(false);
	const [errorOrderItemRow, setErrorOrderItemRow] = React.useState([]);
	const [moq, setMOQ] = React.useState(0);
	const [multiples, setMultiples] = React.useState(1);
	const [rateCard, setRateCard] = React.useState([]);


	const columns = [
        { id: 'slno', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No.', sortable: false },
        { id: 'product', align: 'center', numeric: false, disablePadding: true, label: 'Product', sortable: false },
        { id: 'count', align: 'center', numeric: true, disablePadding: false, label: 'Count', sortable: false },
        // { id: 'size', align: 'center', numeric: true, disablePadding: false, label: 'Size', sortable: false },
        { id: 'format', align: 'center', numeric: true, disablePadding: false, label: 'Format', sortable: false },
        { id: 'action', align: 'center', numeric: false, disablePadding: false, label: '', sortable: false },
    ];

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			setOrder({});
			showProgressDialog();
			fetchMaster();
		}
		return () => {
			setOrder({});
			setOpen(false);
		}
	},[props.open]);

	const fetchMaster = () => {
		let reqs = [MasterService.getMOQ(), MasterService.getRateCard(), MasterService.getConfig('multiples')];
		if(id){
			reqs.push(OrdersService.getOrderDetails(id))
		}
		Promise.all(reqs)
		.then(datas => {
			console.log(datas)
			if(datas[0]){
				setMOQ(datas[0].value ? toInt(datas[0].value) : 0)
			}
			if(datas[2]){
				setMultiples(datas[2].value ? toInt(datas[2].value) : 1)
			}
			if(datas[1]){
				let d = datas[1].map(s => ({...s, value: parseFloat(s.value), unit: toInt(s.unit)})).sort((a, b) => a.unit - b.unit)
				console.log(d)
				setRateCard(d)
			}
			if(datas[2]){
				if(id){
					OrdersService.getOrderDetails(id)
					.then(data => {
						if(data){
							if(id){
								console.log(data);
								setDisableEdit(!state.user || !state.user.roles.includes(ROLES.ADMIN));
								setOrder(order => ({
									...order, 
									id: data.id, 
									client_id: data.client_id,
									client_name: data.client_name,
									amount: data.amount,
									order_date: data.order_date,
									created_date: data.created_date,
									modified_date: data.modified_date,
									status: data.status,
									//Shipment
									shipment_vendor_id: data.shipment_vendor_id,
									shipment_tracking: data.shipment_tracking,
									shipment_date: data.shipment_date,
									shipment_vendor_name: data.shipment_vendor_name,
									//Payment
									payment_mode: data.payment_mode,
									payment_info: data.payment_info,
									payment_date: data.payment_date,
								}));
								let errRows = [], itemRows = [];
								data.items.forEach((i, index) => {
									let x = {
										id: i.id,
										rowid: counter++,
										product_id: i.product_id,
										count: i.count,
										format: i.format,
										size: i.size,
										item: i.product_details,
									};
									itemRows.push(x);
									errRows.push({...x,
										item: false, size: false, format: false, count: false
									})
								});
								setErrorOrderItemRow(errRows);
								setOrderItemRows(itemRows);
							} else {
								setOrder(order => ({...order}));
							}
						}
						closeProgressDialog();
					})
					.catch(error => {
						console.log(error);
						showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
							handleClose(false);
							closeConfirmDialog1D();
						}, 'OK');
						closeProgressDialog();
					});
		
				}
			}
		})
		.finally(() => closeProgressDialog())
	}

	const handleClose = (showConfirm = false) => {
		if(showConfirm){
			showConfirmDialog('Are you sure you want to close. Any changes made will be lost?', undefined, () => {
				closeConfirmDialog();
				close();
			});
		} else {
			close();
		}
		
	};

	const close = () => {
		setOrder({});
		setOrderItemRows([]);
		setErrorOrderItemRow([]);
		props.onClose();
		setOpen(false);
	}

	// const handleChange = field => e => {
	// 	let value = e.target.value;
	// 	setOrder(order => ({...order, [field]: value}));
	// }

	const handleRowChange = rowid => e => {
		let name = e.target.name;
		let value = e.target.value;
		setOrderItemRows(orderItemRows.map(row => 
			row.rowid === rowid
			?
				{...row, [name]: value}
			:	
				row
			)
		);
		setErrorOrderItemRow(rows => rows.map(row => 
			row.rowid === rowid
			?
			{...row, [name]: name === 'count' ? value < moq || (value % multiples !== 0) : !value}
			:row
		));
	}
	
	const handleRowItemChange = rowid => item => {
		setOrderItemRows(orderItemRows.map(row => 
			row.rowid === rowid
			?
			{...row, item}
			:row
			)
		);
		setErrorOrderItemRow(rows => rows.map(row => 
			row.rowid === rowid
			?
			{...row, item: !item}
			:row
		));
	}
	
	const handleRowFormatChange = rowid => format => {
		setOrderItemRows(orderItemRows.map(row => 
			row.rowid === rowid
			?
			{...row, format: format.key}
			:row
			)
		);
		setErrorOrderItemRow(rows => rows.map(row => 
			row.rowid === rowid
			?
			{...row, format: !format}
			:row
		));
	}
	
	const handleRowRemove = rowid => e => {
		setOrderItemRows(orderItemRows.filter(r => r.rowid !== rowid));
	}

	React.useEffect(() => {
		if(orderItemRows.length){
			recalculateOrderTotal()
		}
	}, [JSON.stringify(orderItemRows)])

	const recalculateOrderTotal = () => {
		let amount = 0;
		if(toInt(state.user.free_client || 0) === 1){
			amount = 0
		} else {
			amount = orderItemRows.reduce((sum, next) => {
				let rate = 0;
				for(let i=0; i < rateCard.length; i++){
					if(i === 0){
						if(next.count < rateCard[i].unit){
							rate = rateCard[i].value;
							break;
						}
					} else if(rateCard[i-1].unit <= next.count && next.count < rateCard[i].unit) {
						rate = rateCard[i-1].value;
						break;
					} else if(i === rateCard.length-1 && next.count >= rateCard[i].unit){
						rate = rateCard[i-1].value;
						break;
					}
				}
				return sum + (next.count * rate)
			}, 0);
		}
		setOrder(order => ({...order, amount}))
	}

	const addRow = () => {
		let row = {
            rowid : counter++,
            product_id: null,
            count: moq,
			format: null,
			size: 'not-used',
			item: null,
        }
        setOrderItemRows([...orderItemRows, row]);
        setErrorOrderItemRow([...errorOrderItemRow, {...row, item: false, size: false, format: false, count: false}]);
	}

	const saveOrder = () => {
		if(isValid()){
			showConfirmDialog('Confirm save order?', undefined, () => {
				closeConfirmDialog()
				save()
			})
		}
	}

	const isValid = () => {
		let valid = true;
		if(orderItemRows.length === 0){
			showConfirmDialog('Please add atleast 1 item');
			valid = false;
		} else {
			let i = orderItemRows.filter(i => !i.item || !i.size || !i.format || i.count < moq || (i.count % multiples !== 0));
			if(i.length > 0){
				valid = false;
				i.forEach(i => {
					setErrorOrderItemRow(rows => rows.map(row => 
						row.rowid === i.rowid
						?
						{...row, item: !i.item, count: i.count < moq || (i.count % multiples !== 0), format: !i.format, size: !i.size}
						:row
					));
				})
			}
		}

		return valid;
	}

	const save = () => {
		showProgressDialog('Placing Order')
		let formData = {};
		formData.orderid = order.id || null;
		formData.amount = order.amount || 0;
		
		let items = orderItemRows.map(i => ({
			id: i.id || null,
			product_id: i.item.id,
			count: i.count,
			size: i.size,
			format: i.format,
		}));
		formData.orderitems = items;

		OrdersService.saveOrder(formData, {'admin-request': state.user && state.user.roles.includes(ROLES.ADMIN) ? true: false})
        .then(data => {
            if(data.success){
				showSnackBar('Order Saved', 'info');
				handleClose(false);
				openViewOrder(data.id);
            } else {
				showConfirmDialog1D(data.message, undefined, () => {
					closeConfirmDialog1D();
				});
			}
			closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to Save order. Please try again.', 'error');
			closeProgressDialog();
        })
		.finally(() => closeProgressDialog())
	}

  	return (
		<Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose(true)} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{`${id ? '' : 'New '}Order`}
				</Typography>
				{/* <Button autoFocus color="inherit" onClick={handleClose}>
					save
				</Button> */}
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
						size={40}
						thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} justify="center">
				<Grid container item spacing={2} xs={12} lg={12} alignItems="flex-start" justify="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Order Items"
								titleTypographyProps={{variant: 'h4'}}
								action={
									<IconButton disabled={disableEdit ? true : false} aria-label="add-row" onClick={addRow}>
										<AddCircleIcon />
									</IconButton>
								}
							/>
							<Divider/>
							<Table>
								<EnhancedTableHead
									order={""}
									orderBy={""}
									onRequestSort={() => {}}
									rows={columns}  />
								<TableBody>
									{orderItemRows.map((row, i) => 
										<TableRow key={i}>
											<StyledTableCell scope="row" align="justify" padding='none'>
												<div style={{ marginBottom: 16, fontWeight: 900}}>{i + 1}</div>
											</StyledTableCell>
											<StyledTableCell scope="row" align="justify" padding='none' style={{minWidth: '20vw', marginBottm: 8}}>
												<ItemSelectionAsync
													required 
													size={'small'}
													label='Product'
													value={row.item || null} 
													optionLabel='name'
													helperText={'Type in a Product Name'}
													labelTemplate={option => option.name}
													error={errorOrderItemRow.find(i => i.rowid === row.rowid).item || false} 
													style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
													formClass={classes.formAutoClass}
													selected={handleRowItemChange(row.rowid)}
													//renderOption={(option) => {
													//	return (
													//		<Grid container alignItems="center">
													//			<Grid item>
													//				<LocationOnIcon className={classes.icon} />
													//			</Grid>
													//			<Grid item xs>
													//			<span style={{ fontWeight: 400 }}>
													//				{option.pincode}
													//			</span>
													//			<Typography variant="body2" color="textSecondary">
													//				{`${option.city}, ${option.state}`}
													//			</Typography>
													//			</Grid>
													//		</Grid>
													//	);
													//}}
												/>
											</StyledTableCell>
											<StyledTableCell scope="row" align="right" padding='none'>
												<TextField 
													disabled={disableEdit ? true: false}
													name="count"
													variant="outlined"
													margin="dense"
													error={errorOrderItemRow.find(i => i.rowid === row.rowid).count || false}
													value={row.count ? row.count : ''} 
													inputProps={{
														//startAdornment: <InputAdornment position="start">Nos</InputAdornment>,
														min: moq,
														step: multiples,
													}}
													required
													style={{marginTop: 8}}
													type="number"
													helperText={`Minimum Nos: ${moq} ${ multiples ? ` in the multples of ${multiples}` : ''} `}
													onChange={handleRowChange(row.rowid)}/>
											</StyledTableCell>
											{/* <StyledTableCell scope="row" align="right" padding='none'>
												<TextField 
													name="size"
													disabled={disableEdit ? true: false}
													error={errorOrderItemRow.find(i => i.rowid === row.rowid).size || false}
													variant="outlined"
													margin="dense"
													required
													value={row.size ? row.size : ''} 
													inputProps={{
														//startAdornment: <InputAdornment position="start">Gram</InputAdornment>,
														min: 0
													}}
													style={{marginTop: 8}}
													type="text"
													helperText="25x25cm"
													onChange={handleRowChange(row.rowid)}/>
											</StyledTableCell> */}
											<StyledTableCell scope="row" align="right" padding='none'>
												<ItemSelection
													required 
													label='Format'
													value={row.format ? formatTypes.find(k => k.key === row.format)  : null} 
													optionLabel='label'
													error={errorOrderItemRow.find(i => i.rowid === row.rowid).format || false}
													options={formatTypes} 
													style={{zIndex: 500, marginTop: 0}}
													formClass={classes.formAutoClass}
													selected={handleRowFormatChange(row.rowid)}
													helperText={"Shape of the printed QR code"}/>
											</StyledTableCell>
											<StyledTableCell scope="row" align="right" padding='default'>
												<Button disabled={disableEdit ? true: false}>
													<Icon style={{marginTop: 8, marginBottom: 22}} onClick={handleRowRemove(row.rowid)}>delete</Icon>
												</Button>
											</StyledTableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
			<AppBar position="fixed" color="default" className={classes.appBar}>
        		<Toolbar>
					<Typography variant="h6" className={classes.summary}>
						{`Total Amount`}
						<Typography variant="h6" component="span" style={{marginLeft: 24}}>
							{formatNumber(order.amount || 0)}
						</Typography>
					</Typography>
					<Button autoFocus variant="contained" color="primary" onClick={saveOrder}>
						save
					</Button>
				</Toolbar>
			</AppBar>
		</Dialog>
	);
}

CreateOrderRequest.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};