import Config from '../Config';
import { format } from '../Utils';
import moment from 'moment';
import { RestService } from './RestService';

export const ProductService = {
    getProductsCount: async function(filter, statuses){
        return RestService.get(`${Config.products_list_url}?count=true${ filter && filter.length > 0 ? `&filter=${filter}` : '' }${statuses ? `&statuses=${statuses}` : ''}`, {})
        .then(response => response.json());
    },
    getProducts: async function(filter, sort = 'name', direction = 'asc', statuses, start = 0, offset = 20){
        return RestService.get(`${Config.products_list_url}?${ filter && filter.length > 0 ? `filter=${filter}` : '' }${statuses ? `&statuses=${statuses}` : ''}&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}&fields=true`, {})
        .then(response => response.json());
    },
    createProduct: async function(headers, body){
        return RestService.postFormData(Config.product_url, headers, body)
        .then(response => response.json());
    },
    addProductField: async function(headers, id, body){
        return RestService.post(Config.product_custom_field_url.replace('{productId}', id), headers, body)
        .then(response => response.json());
    },
    removeProductField: async function(headers = {}, pId, fId){
        return RestService.delete(Config.product_custom_field_delete_url.replace('{productId}', pId).replace('{fieldId}', fId), headers);
    },
    removeProduct: async function(headers = {}, pId){
        return RestService.delete(Config.product_id_url.replace('{productId}', pId), headers);
    },
    changeProductStatus: async function(headers = {}, pId, status){
        return RestService.post(Config.product_status_url.replace('{id}', pId).replace('{status}', status), headers)
        .then(response => response.json());
    },
    getProductQRScansCount: async function(selectedProductId = null, filter= '', customer = null, source = null, opened = 1, startdate = null, enddate = null){
        return RestService.get(`${Config.product_qr_list_url.replace('{id}', selectedProductId)}?count=true${ filter && filter.length > 0 ? `&filter=${filter}` : '' }${ customer ? `&customer=${customer}` : '' }${ source ? `&source=${source}` : '' }${ opened ? `&opened=${opened}` : '' }&startdate=${moment(startdate).startOf('day').utc().format(format)}&enddate=${moment(enddate).endOf('day').utc().format(format)}`, {})
        .then(response => response.json());
    },
    getProductQRScans: async function(selectedProductId, filter= '', customer = null, source = null, opened = 1, startdate = null, enddate = null, sort = 'modified_date', direction = 'desc', start = 0, offset = 20){
        return RestService.get(`${Config.product_qr_list_url.replace('{id}', selectedProductId)}?${ filter && filter.length > 0 ? `&filter=${filter}` : '' }${ customer ? `&customer=${customer}` : '' }${ source ? `&source=${source}` : '' }${ opened ? `&opened=${opened}` : '' }&startdate=${moment(startdate).startOf('day').utc().format(format)}&enddate=${moment(enddate).endOf('day').utc().format(format)}&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.json());
    },
    getProductQRScansExport: async function(selectedProductId, filter= '', customer = null, source = null, opened = 1, startdate = null, enddate = null, sort = 'modified_date', direction = 'desc', start = 0, offset = 20){
        return RestService.get(`${Config.product_qr_list_url.replace('{id}', selectedProductId)}?export=true&${ filter && filter.length > 0 ? `&filter=${filter}` : '' }${ customer ? `&customer=${customer}` : '' }${ source ? `&source=${source}` : '' }${ opened ? `&opened=${opened}` : '' }&startdate=${moment(startdate).startOf('day').utc().format(format)}&enddate=${moment(enddate).endOf('day').utc().format(format)}&from=${start}&offset=${offset}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.blob());
    },
};