export const Constants = {
  
};

export const STATUS = {
  "CANCELLED": "-1",
  "NEW": "0",
  "PAID": "1",
  "INPROGRESS": "2",
  "PRINTED": "3",
  "SHIPPED": "4",
  "COMPLETED": "5"
};

export const SMS_TYPE = {
  P: "payment",
  S: "shipped",
};

export const TABLE_COUNTS = {
  product_tab_count: "eauthenticity.product_tab_count",
  logs_tab_count: "eauthenticity.logs_tab_count",
  messages_tab_count: "eauthenticity.messages_tab_count",
  customer_tab_count: "eauthenticity.customer_tab_count",
  orders_tab_count: "eauthenticity.orders_tab_count",
}

export const FIELD_TYPES = [
  {
    key: "text",
    label: "Text",
  },
  {
    key: "link",
    label: "Link",
  }
]

export const PROFILE_OPTIONS = [
  {
    type: "fb",
    label: "Facebook",
    // type: "social",
    link: "/images/facebook-icon_square_32x32.png"
  },
  {
    type: "amazon",
    label: "Amazon",
    // type: "ecommerce",
    link: "/images/amazon_square_32.png"
  },
  {
    type: "email",
    label: "Email",
    // type: "contact",
    link: "/images/brands/email.png"
  },
  {
    type: "flipkart",
    label: "Flipkart",
    // type: "ecommerce",
    link: "/images/flipkart_square_32.png"
  },
  {
    type: "google",
    label: "Google My Business",
    // type: "contact",
    link: "/images/google-my-bussiness_square_32.png"
  },
  {
    type: "instagram",
    label: "Instagram",
    // type: "social",
    link: "/images/instagram-icon_square_32x32.png"
  },
  {
    type: "pinterest",
    label: "Pinterest",
    // type: "social",
    link: "/images/pinterest-icon_square_32x32.png"
  },
  {
    type: "customercare",
    label: "Customer Care",
    // type: "contact",
    link: "/images/brands/support.png"
  },
  {
    type: "telegram",
    label: "Telegram",
    // type: "contact",
    link: "/images/brands/telegram.png"
  },
  {
    type: "twitter",
    label: "Twitter",
    // type: "social",
    link: "/images/twitter-icon_square_32x32.png"
  },
  {
    type: "whatsapp",
    label: "Whatsapp",
    // type: "contact",
    link: "/images/whatsapp_square_32.png"
  },
  {
    type: "youtube",
    label: "Youtube",
    // type: "social",
    link: "/images/youtube-icon_square_32x32.png"
  },
  {
    type: "website",
    label: "Website",
    // type: "social",
    link: "/images/brands/website.png"
  },
]