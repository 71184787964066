import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./HomePage/home";
import Login from "./LoginPage/Login";
import NotFound from "./NotFound/NotFound";

// Components
import ViewLogs from "./LogsPage/ViewLogs";
import Customer from "./CustomerPage/Customer";
import Product from "./ProductPage/Products";
import Message from "./MessagePage";
import Profile from "./ProfilePage";
import ShipmentVendor from "./ShipmentVendorPage";
import OpenOrderDetails from "./OrdersPage/OpenOrderDetails";
import ViewClientOrders from "./OrdersPage/ViewClientOrders";
import ViewOrders from "./OrdersPage/ViewOrders";
import ManageConfig from "./ConfigPage/ManageConfig";
import QRScanPage from "./QRScanPage";

export default () =>
  <Switch>
    <Route path={`/login`} exact component={Login} />
    <Route path={`/`} exact component={Home} />
    
    {/* ADMIN */}
    <Route path={`/manage/customer`} exact component={Customer} />
    <Route path={`/manage/products`} exact component={Product} />
    <Route path={`/manage/profile`} exact component={Profile} />
    <Route path={`/manage/messages`} exact component={Message} />
    <Route path={`/view/logs/:type`} component={ViewLogs} />

    {/* SUPER ADMIN */}
    {/* <Route path={`/report/pledge/outstanding`} exact component={Home} />
    <Route path={`/report/pledge/due`} exact component={Home} />
    <Route path={`/report/pledge/outstanding/datewise`} exact component={Home} />
    <Route path={`/report/pledge/release`} exact component={Home} />
    <Route path={`/report/loyalty/analysis`} exact component={Home} /> */}
    <Route path={`/view/qr/scans`}  component={QRScanPage} />
    <Route path={`/order/:no`}  component={OpenOrderDetails} />
    <Route path={`/manage/client/orders`} exact component={ViewClientOrders} />
    <Route path={`/view/orders`} exact component={ViewOrders} />
    <Route path={`/manage/shipment/vendors`} exact component={ShipmentVendor} />
    <Route path={`/manage/config/:tab`} exact component={ManageConfig} />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;