import Config from '../Config';
import { RestService } from './RestService';
import moment from 'moment';
import { format } from '../Utils';

export const LogsService = {
    getLogsCount: async function(type, startdate, enddate){
        return RestService.get(`${Config.txn_logs_url}?count=true&type=${type}&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}`, {})
        .then(response => response.json());
    },
    getLogs: async function(type, startdate, enddate, sort, direction, start, offset){
        return RestService.get(`${Config.txn_logs_url}?type=${type}&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.json());
    },
};