import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, Tooltip, IconButton } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { STATUS, TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, formatNumber, getTimeInIST, format_display_fancy_notime, getStatusBadge, titleCase } from "../Utils";
import moment from "moment";
import SingleDate from "../components/SingleDate";
import SearchBar from "../components/SearchBar";
import ItemSelection from "../components/ItemSelection";
import { OrdersService } from "../Services/OrdersService";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    searchBar: {
        maxWidth: 180,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ViewOrders(props){

    const {setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, openViewOrder} = useContext(AppContext);
    const classes = useStyles();
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [startdate, setStartDate] = React.useState(moment().startOf('day').startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const statuses = Object.keys(STATUS).map((key) =>
        ({name: titleCase(key), value: STATUS[key]})
    )
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_date');
    const [orders, setOrders] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'order_no', align: 'justify', numeric: false, disablePadding: false, label: 'Order No', sortable: true },
        // { id: 'client_name', align: 'center', numeric: false, disablePadding: true, label: 'Client Name', sortable: true },
        { id: 'order_date', align: 'center', numeric: false, disablePadding: true, label: 'Order Date', sortable: true },
        { id: 'amount', align: 'center', numeric: true, disablePadding: true, label: 'amount', sortable: true },
        { id: 'payment_date', align: 'center', numeric: false, disablePadding: true, label: 'Paid On', sortable: true },
        { id: 'shipment_date', align: 'center', numeric: false, disablePadding: true, label: 'Shipped Date', sortable: true },
        { id: 'status', align: 'center', numeric: false, disablePadding: true, label: 'Status', sortable: true },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.orders_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.orders_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.orders_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('View Client orders');
        setLoadedMaster(true);
        return () => {
            setTitle('');
        }
    }, [true]);

    // React.useEffect(() => {
    //     if(loadedMaster){
    //         closeProgressDialog();
    //     }
    // }, [loadedMaster]);

    React.useEffect(() => {
        if(generate && loadedMaster){
            setOrders([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // setToolTitle(undefined);
            if(initialLoading){
                showProgressDialog();
                setInitialLoading(false);
            }
            let selectedStatusId = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            OrdersService.getOrdersCount(startdate, enddate, fc, null, selectedStatusId)
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setOrders([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate, loadedMaster]);

    const openDialog = (orderNo) => {
        openViewOrder(orderNo, (shouldRefresh) => {
            if(shouldRefresh){
                setGenerate(true);
            }
        });
    }

    // const handleEdit = (pledgeNo) => {
    //     console.log(pledgeNo);
    //     openCreateOrder(pledgeNo);
    // }

	// const cancel = (pledgeNo) => {
	// 	showProgressDialog('Cancelling Loan...');
	// 	LoanRequestsService.cancelRequest(pledgeNo)
	// 	.then(data => {
    //         showSnackBar('Loan Cancelled', 'info');
    //         closeProgressDialog();
    //         setGenerate(true);
	// 	})
	// 	.catch(error => {
	// 		console.log(error);
	// 		showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
	// 			closeConfirmDialog();
	// 		});
	// 		closeProgressDialog();
	// 	})
    // }
    
    // const handleCancelLoan = (pledgeNo) => {
    //     console.log(pledgeNo);
    //     showConfirmDialog(`Confirm Cancel Loan #${pledgeNo} ?`, undefined, () => {
    //         closeConfirmDialog();
    //         cancel(pledgeNo);
    //         // FinanceService.removeTransaction(loan, id)
    //         // .then(() => {
    //         //     showSnackBar(`Removed Tranaction`, 'success');
    //         //     setGenerate(true);
    //         // })
    //         // .catch((error) => {
    //         //     console.log(error);
    //         //     showSnackBar(`Failed to remove. Please try again or Contact Admin`, 'error');
    //         // })
    //     });
    // }

    React.useEffect(() => {
        if(fetchPage) {
            setLinearLoading(true);
            let selectedStatusId = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            OrdersService.getOrders(startdate, enddate, fc, null, selectedStatusId, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                setOrders(data || []);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection 
                                        multiple 
                                        clearable={true} 
                                        label='Status' 
                                        value={selectedStatuses} 
                                        optionLabel='name' 
                                        options={statuses} 
                                        selected={setSelectedStatuses}/>
                                </Grid>
                                <Grid item>
                                    <div style={{maxWidth: 170}}>
                                        <SingleDate 
                                            label="From"
                                            date={startdate}
                                            maxDate={moment()}
                                            setDate={(date) => {
                                                setStartDate(date);
                                            }}
                                            onKeyUp={(event) => {
                                                event.preventDefault();
                                                if (event.key === 'Enter'){
                                                    !generate && setGenerate(true)
                                                }
                                            }}/>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{maxWidth: 170, marginLeft: 8}}>
                                        <SingleDate 
                                            label="To"
                                            date={enddate}
                                            minDate={startdate}
                                            maxDate={moment()}
                                            setDate={(date) => {
                                                setEndDate(date);
                                            }}
                                            onKeyUp={(event) => {
                                                event.preventDefault();
                                                if (event.key === 'Enter'){
                                                    !generate && setGenerate(true)
                                                }
                                            }}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                        {
                                            loadedMaster && (
                                                <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                                    <SearchBar 
                                                        className={classes.searchBar}
                                                        hint="Search..."
                                                        searchText={filter}
                                                        onTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setGenerate(true);
                                                    }}/>
                                                </div>
                                            )
                                        }
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Refresh">
                                        <IconButton size="medium" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                            <Icon>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {
                                                    orders && orders.map((element, index) => {
                                                        return (
                                                            <TableRow 
                                                                key={index} 
                                                                hover
                                                                style={{cursor: 'pointer'}} 
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openDialog(element.id);
                                                                }}>
                                                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 20}}>
                                                                    {index + 1 + (currentPageNumber * numItemsPerPage)}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="justify" padding='none'>
                                                                    {element.order_no || ''}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="center" padding='none'>
                                                                    {element.order_date ? getTimeInIST(element.order_date).format(format_display_fancy_notime) : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                                                    {element.amount && formatNumber(element.amount)}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="center" padding='none'>
                                                                    {element.payment_date ? getTimeInIST(element.payment_date).format(format_display_fancy_notime) : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="center" padding='none'>
                                                                    {element.shipment_date ? getTimeInIST(element.shipment_date).format(format_display_fancy_notime) : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                                                    {element.status ? getStatusBadge(`${element.status}`) : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                                                    {/* {
                                                                        (element.status === STATUS.ACTIVE && state.user.roles.includes(ROLES.ADMIN)) && (
                                                                            <BootstrapTooltip arrow title="Edit">
                                                                                <Icon 
                                                                                    fontSize="small" 
                                                                                    style={{marginTop: 8, marginRight: 8}} 
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleEdit(element.pledge_no)
                                                                                    }}>edit</Icon>
                                                                            </BootstrapTooltip>
                                                                        )
                                                                    }
                                                                    {
                                                                        (element.status === STATUS.ACTIVE && state.user.roles.includes(ROLES.ADMIN)) && (
                                                                            <BootstrapTooltip arrow title="Cancel">
                                                                                <Icon 
                                                                                    fontSize="small" 
                                                                                    style={{marginTop: 8, marginRight: 8}} 
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleCancelLoan(element.pledge_no)
                                                                                    }}>cancel</Icon>
                                                                            </BootstrapTooltip>
                                                                        )
                                                                    } */}
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Orders</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}