import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddVendorDialog(props) {
    const [open, setOpen] = React.useState(false);    

    React.useEffect(() => {
        if(props.open){
            setOpen(props.open);
        } else {
          setOpen(props.open);
        }
    }, [props.open]);
 
    const handleClose = () => {
        props.close();
        setOpen(false);
    };

    return (
        <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ?  props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogContent dividers style={{paddingTop: 16, }}>
                {props.content}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button variant="contained" autoFocus onClick={props.save} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddVendorDialog.prototype = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    content: PropTypes.any.isRequired,
    maxWidth: PropTypes.any,
    fullWidth: PropTypes.bool
};