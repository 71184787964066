import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Link, Typography, IconButton, TextField } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { PROFILE_OPTIONS } from "../Constants";
import { BootstrapTooltip } from "../Utils";
import { ProfileService } from "../Services/ProfileService";
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ClearIcon from '@material-ui/icons/Clear';
import AddProfileItemDialog from "./AddProfileItemDialog";

const useStyles = makeStyles(theme => ({
    page: {
        width: '100%',
        marginTop: 16
    },
    extendedIcon: {
        position: 'absolute',
        right: 30,
        bottom: 30,
    },
    eIcon: {
        marginRight: theme.spacing(1),
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            // float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function Profile(props){

    const { setTitle, setLinearLoading, showConfirmDialog, closeConfirmDialog, showSnackBar, showProgressDialog, closeProgressDialog } = useContext(AppContext);
    const classes = useStyles();
    const [profileData, setProfileData] = useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [showEditOpen, setShowEditOpen] = React.useState(false);
    const [selectedType, setSelectedType] = React.useState(null)

    React.useEffect(() => {
        setTitle('Manage Profile');
        setGenerate(true);
    }, [true]);

    React.useEffect(() => {
        if(generate){
            setGenerate(false);
            setLinearLoading(true);
            ProfileService.getProfile()
            .then(data => {
                console.log(data);
                setProfileData(data)
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
        }
    }, [generate]);

    const edit = (id, type, display_label, content) => {
        setShowEditOpen(true)
        setSelectedType({id, type, display_label, content})
    }

    const handleChange = (e) => {
        e.persist()
        setSelectedType(type => ({...type, [e.target.name]: e.target.value}))
    }
    
    const handleClose = () => {
        setShowEditOpen(false)
        setSelectedType(null)
    }

    const clear = (id) => {
        showConfirmDialog('Confirm Reset?', undefined, () => {
            closeConfirmDialog()
            showProgressDialog()
            
            ProfileService.removeProfileEntry(id)
            .then(data => {
                showSnackBar('Resetted Successfully.', "info");
                handleClose()
                setGenerate(true)
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Something went wrong. Please try again later', "error");
                closeProgressDialog();
            })
        })
    }
    
    const handleSave = () => {
        if(isValid()){
            showConfirmDialog('Confirm Save', undefined, () => {
                closeConfirmDialog()
                showProgressDialog()
                let body = [{
                    id : selectedType.id || null,
                    type : selectedType.type || null,
                    content : selectedType.content || null,
                    display_label : selectedType.display_label || PROFILE_OPTIONS.find(p => p.type === selectedType.type).label
                }]
                ProfileService.createProfileEntry(body)
                .then(data => {
                    if(data.success){
                        showSnackBar('Updated Successfully.', "info");
                        handleClose()
                        setGenerate(true)
                        closeProgressDialog();
                    } else {
                        showSnackBar('Something went wrong. Please try again.', "error");
                        closeProgressDialog();
                    }
                })
                .catch(error => {
                    console.log(error);
                    showSnackBar('Something went wrong. Please try again later', "error");
                    closeProgressDialog();
                })
            })
        } else {
            showSnackBar('Plese fill all the fields with valid values')
        }
    }

    const isValid = () => {
        if(!selectedType.content){
            return false
        }
        return true;
    }

    return (
         <div className={classes.page}>
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="flex-start">
                    
                       {
                            PROFILE_OPTIONS.map((option, i) => {
                                let v = profileData.length > 0 ? profileData.find(i => i.type === option.type): {}
                                return (
                                    <Grid key={i} item xl={4} lg={4} xs={12}>
                                        <Row  {...option} display_label={v ? v.display_label : option.label} content={v ? v.content : null} id={v ? v.id : null} edit={edit} clear={clear}/>
                                    </Grid>
                                )
                            })
                       } 
                    
                </Grid>
                <AddProfileItemDialog
                    maxWidth={"sm"}
                    content={
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Display Label"
                                        placeholder="Display Label"
                                        name="display_label"
                                        disabled={true}
                                        value={selectedType ? selectedType.display_label : undefined}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        style={{marginLeft: 8, paddingRight: 10}}
                                        onChange={handleChange}
                                        InputProps={{
                                            inputProps: { min: 1, maxLength: 200 }
                                        }}
                                        helperText={`${(selectedType && selectedType.display_label ? selectedType.display_label.length : 0)} / 200`}
                                        required
                                        fullWidth
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="URL"
                                        placeholder="URL"
                                        name="content"
                                        value={selectedType ? selectedType.content : undefined}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        style={{marginLeft: 8, paddingRight: 10}}
                                        onChange={handleChange}
                                        InputProps={{
                                            inputProps: { min: 1, maxLength: 300 }
                                        }}
                                        helperText={`${(selectedType && selectedType.content ? selectedType.content.length : 0)} / 300`}
                                        required
                                        fullWidth
                                        />
                                </Grid>
                            </Grid>
                        }
                    title={''}
                    open={showEditOpen}
                    save={handleSave}
                    close={handleClose}/>
            </div>
        </div>
    );
}


const Row = (props) => {
    const classes = useStyles();
    const {label, id, type, link, content, display_label, edit, clear} = props;

    return (
        <Paper className={classes.section}>
            <div style={{minHeight: 100, marginTop: 8, marginBottom: 8, marginLeft: 8}}>
                <div style={{float: 'left', width: '90%', height: '100%'}}>
                    <Grid container direction="row" spacing={1} justify="space-between" alignItems="flex-start">
                        <Grid item xs={2}>
                            <img src={process.env.PUBLIC_URL +link} alt={label} width={50} height={50}/>
                        </Grid>
                        <Grid item container xs={10} justify="flex-start">
                            <Grid item xs={4}>
                                <Typography variant="body1" align="left">
                                    Label
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" align="left">
                                    {display_label}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" align="left">
                                    Link
                                </Typography>
                            </Grid>
                            <Grid item xs={8} align="left">
                                <Typography variant="body1" style={{overflow: 'auto', textOverflow: 'ellipsis'}}>
                                    <Link href={content} target="_blank">
                                        <span>{content || ''}</span>
                                        {
                                            content && (
                                                <IconButton size="small">
                                                    <OpenInNewIcon style={{fontSize: 20, padding: 0}}/>
                                                </IconButton>
                                            )
                                        }
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{float: 'right', width: '10%', height: '100%'}}>
                    <BootstrapTooltip title="Edit" arrow>
                        <IconButton size="small" style={{marginRight: 8}} onClick={() => edit(id, type, display_label, content)}>
                            <EditIcon style={{fontSize: 20, padding: 0}}/>
                        </IconButton>
                    </BootstrapTooltip>
                    {
                        content && (
                            <BootstrapTooltip title="Clear" arrow>
                                <IconButton size="small" style={{marginRight: 8}} onClick={() => clear(id)}>
                                    <ClearIcon style={{fontSize: 20, padding: 0}}/>
                                </IconButton>
                            </BootstrapTooltip>
                        )
                    }
                </div>
            </div>
        </Paper>
    )
}