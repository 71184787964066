import Config from '../Config';
import {RestService} from './RestService';

export const MasterService = {
  getShipmentVendors: async function () {
    return RestService.get(Config.shipment_vendors_url, {}).then((response) =>
      response.json()
    );
  },
  createShipmentVendor: async function (body, headers = {}) {
    return RestService.post(
      Config.shipment_vendor_url,
      headers,
      body
    ).then((response) => response.json());
  },
  removeShipmentVendor: async function (id) {
    return RestService.delete(
      Config.shipment_vendor_id_url.replace("{id}", id),
      {}
    );
  },
  getMasterSourceTypes: async function(){
    return RestService.get(Config.master_source_types_url, {})
    .then(response => response.json());
  },
  getMasterFormatTypes: async function(){
    return RestService.get(Config.master_format_types_url, {})
    .then(response => response.json());
  },
  getMOQ: async function(){
    return RestService.get(Config.get_moq_url, {})
    .then(response => response.json());
  },
  getConfig: async function(type){
    return RestService.get(`${Config.get_config_by_type_url}?type=${type}`, {})
    .then(response => response.json());
  },
  getRateCard: async function(){
    return RestService.get(Config.get_rates_url, {})
    .then(response => response.json());
  },
  updateConfig: async function(body, headers = {}){
    return RestService.post(Config.update_config_url, headers, body)
    .then(response => response.json());
  },
  removeConfig: async function (id) {
    return RestService.delete(
      Config.remove_config_url.replace("{id}", id),
      {}
    );
  },
};