import Config from '../Config';
import {RestService} from './RestService';

export const ManagementService = {

    getGoldRate: async function(){
        return RestService.get(Config.gold_rate_url, {})
                .then(response => response.json());
    },
    updateRate: async function(rate){
        return RestService.post(Config.update_gold_rate_url.replace("{rate}", rate), {})
                .then(response => response.json());
    },
    getSummary: async function(startdate, enddate){
        return RestService.get(Config.get_summary_url.replace("{startdate}", startdate).replace("{enddate}", enddate), {})
        .then(response => response.json());
    },
    getServiceableAreas: async function(){
        return RestService.get(Config.get_serviceareas_url, {})
        .then(response => response.json());
    },
    createServiceableArea: async function(headers, body) {
        return RestService.post(Config.create_servicearea, headers, body)
        .then(response => response.json());
    },
    removeServiceableArea: async function(pincode) {
        return RestService.post(Config.remove_servicearea, {}, { pincode : pincode })
        .then(response => response.json());
    },
};