import Config from '../Config';
import {RestService} from './RestService';
import { format, format_without_time } from '../Utils';
import moment from 'moment';

export const OrdersService = {
    getOrderExport: async function(id = null){
        return RestService.get(Config.order_export_url.replace('{id}', id), {})
        .then(response => response.blob());
    },
    getOrderDetails: async function(id = null){
        return RestService.get(Config.order_details_url.replace('{id}', id), {})
        .then(response => response.json());
    },
    saveOrder: async function(body, headers = {}){
        return RestService.post(Config.order_url, headers, body)
        .then(response => response.json());
    },
    getOrdersCount: async function(startdate, enddate, fc, selectedClientId, selectedStatusId){
        return RestService.get(`${Config.orders_list_url}?count=true&startdate=${moment(startdate).startOf('day').utc().format(format)}&enddate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedClientId && selectedClientId.length > 1 ? `&client_id=${selectedClientId}` : '' }${ selectedStatusId && selectedStatusId.length > 0 ? `&statuses=${selectedStatusId}` : '' }`, {})
        .then(response => response.json());
    },
    getOrders: async function(startdate, enddate, fc, selectedClientId, selectedStatusId, sort, direction, start, offset){
        return RestService.get(`${Config.orders_list_url}?from=${start}&offset=${offset}&sort=${sort}&direction=${direction}&startdate=${moment(startdate).startOf('day').utc().format(format)}&enddate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedClientId && selectedClientId.length > 1 ? `&client_id=${selectedClientId}` : '' }${ selectedStatusId && selectedStatusId.length > 0 ? `&statuses=${selectedStatusId}` : '' }`, {})
        .then(response => response.json());
    },
    updateShipmentInfo: async function(body, headers = {}){
        return RestService.post(Config.order_shipping_url, headers, body)
        .then(response => response.json());
    },
    updatePaymentInfo: async function(body, headers = {}){
        return RestService.post(Config.order_payment_url, headers, body)
        .then(response => response.json());
    },
    updateStatus: async function(id = null, status = null, headers = {}){
        return RestService.post(`${Config.order_status_url}?id=${id}&status=${status}`, headers, {})
        .then(response => response.json());
    },
    //v2
    getNextPledgeNo: async function(branchId){
        return RestService.get(Config.get_next_pledge_no.replace("{branchId}", branchId), {})
        .then(response => response.json());
    },
    getReferrerSuggestions: async function(){
        return RestService.get(Config.get_ref_suggestions, {})
        .then(response => response.json());
    },
    getLoanHistory: async function(customerId = null){
        return RestService.get(Config.get_loan_history.replace("{customerId}", customerId), {})
        .then(response => response.json());
    },
    reportOutstandingLoanCount: async function(startdate, enddate, fc, selectedBranchIds, schemeType, amountLTE = null, amountGTE = null){
        return RestService.get(`${Config.report_loans_outstanding}count=true${ startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : '' }&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }${ schemeType ? `&scheme_type=${schemeType}` : '' }${ amountLTE ? `&amountLTE=${amountLTE}` : '' }${ amountGTE ? `&amountGTE=${amountGTE}` : '' }`, {})
        .then(response => response.json());
    },
    reportLoanDueCount: async function(startdate, enddate, fc, selectedBranchIds){
        return RestService.get(`${Config.report_loans_due}count=true${ startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : '' }&todate=${moment(enddate).endOf('day').format(format)}&filter=${fc}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
    reportOutstandingLoan: async function(startdate, enddate, fc, selectedBranchIds, schemeType, amountLTE = null, amountGTE = null, sort, direction, start, offset){
        return RestService.get(`${Config.report_loans_outstanding}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}${ startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : '' }&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }${ schemeType ? `&scheme_type=${schemeType}` : '' }${ amountLTE ? `&amountLTE=${amountLTE}` : '' }${ amountGTE ? `&amountGTE=${amountGTE}`: ''}`, {})
        .then(response => response.json());
    },
    reportLoanDue: async function(startdate, enddate, fc, selectedBranchIds, sort, direction, start, offset){
        return RestService.get(`${Config.report_loans_due}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}${ startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : '' }&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
    reportReleasedLoanCount: async function(startdate, enddate, fc, selectedBranchIds, schemes, amountLTE = null, amountGTE = null){
        return RestService.get(`${Config.report_loans_released}count=true${ startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : '' }&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }${ schemes && schemes.length > 1 ? `&schemes=${schemes}` : '' }${ amountLTE ? `&amountLTE=${amountLTE}` : '' }${ amountGTE ? `&amountGTE=${amountGTE}` : '' }`, {})
        .then(response => response.json());
    },
    reportReleasedLoan: async function(startdate, enddate, fc, selectedBranchIds, schemes, amountLTE = null, amountGTE = null, sort, direction, start, offset){
        return RestService.get(`${Config.report_loans_released}from=${start}&offset=${offset}&sort=${sort}&direction=${direction}${ startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : '' }&todate=${moment(enddate).endOf('day').utc().format(format)}&filter=${fc}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }${ schemes && schemes.length > 1 ? `&schemes=${schemes}` : '' }${ amountLTE ? `&amountLTE=${amountLTE}` : '' }${ amountGTE ? `&amountGTE=${amountGTE}`: ''}`, {})
        .then(response => response.json());
    },
    reportOutstandingDateWise: async function(startdate, enddate, selectedBranchIds){
        return RestService.get(`${Config.report_loans_outstanding_datewise}${ startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : '' }&todate=${moment(enddate).startOf('day').format(format)}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
    reportLoyaltyCostAnalysis: async function(startdate, enddate, selectedBranchIds, selectedCriteria){
        return RestService.get(`${Config.report_loyalty_cost_analysis}${ startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : '' }&todate=${moment(enddate).startOf('day').format(format)}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }${ selectedCriteria ? `&criteria=${selectedCriteria}` : '' }`, {})
        .then(response => response.json());
    },
    reportFormG: async function(startdate, enddate, selectedBranchId){
        return RestService.get(`${Config.report_form_g}${ startdate ? `&fromdate=${moment(startdate).startOf('day').utc().format(format)}` : '' }&todate=${moment(enddate).endOf('day').utc().format(format)}${ selectedBranchId && selectedBranchId.length > 1 ? `&branch=${selectedBranchId}` : '' }`, {})
        .then(response => response.json());
    },
    getLoanTransactions: async function(pledgeNo = null){
        return RestService.get(`${Config.get_loan_transactions}pledgeNo=${pledgeNo}`, {})
        .then(response => response.json());
    },
    cancelRequest: async function(pledgeNo = null){
        return RestService.delete(`${Config.get_loan_url}pledgeNo=${pledgeNo}`, {});
    },
    closeRequest: async function(pledgeNo = null, body){
        return RestService.post(`${Config.get_loan_close_url}pledgeNo=${pledgeNo}`, {}, body);
    },
    getLoansSearch: async function(filter, selectedBranchIds, start, offset){
        return RestService.get(`${Config.get_loans_search_url}from=${start}&offset=${offset}&filter=${filter}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
    getLoansDashboardCount: async function(selectedStatusId, selectedBranchIds, startdate, enddate){
        return RestService.get(`${Config.get_loans_count_dashboard}fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }${ selectedStatusId && selectedStatusId.length > 1 ? `&statuses=${selectedStatusId}` : '' }`, {})
        .then(response => response.json());
    },
    getLoansDashboardAmount: async function(selectedBranchIds, disbursed = false, startdate, enddate){
        return RestService.get(`${Config.get_loans_amount_dashboard}disbursed=${disbursed}&fromdate=${moment(startdate).startOf('day').utc().format(format)}&todate=${moment(enddate).endOf('day').utc().format(format)}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
    getLoansDashboardAmountGraph: async function(selectedBranchIds, type = null, startdate, enddate){
        return RestService.get(`${Config.get_loans_amount_dashboard_graph}${ type && type.length > 1 ? `type=${type}` : ''}&fromdate=${moment(startdate).format(format_without_time)}&todate=${moment(enddate).format(format_without_time)}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
    getLoansDashboardInterest: async function(selectedBranchIds, startdate, enddate){
        return RestService.get(`${Config.get_loans_interest_dashboard}fromdate=${moment(startdate).format(format_without_time)}&todate=${moment(enddate).format(format_without_time)}${ selectedBranchIds && selectedBranchIds.length > 1 ? `&branches=${selectedBranchIds}` : '' }`, {})
        .then(response => response.json());
    },
};