import React, { useState, useContext } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { formatNumber, format } from "../Utils";
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
// import { FinanceService } from "../Services/FinanceService";
import { AppContext } from "../AppContextProvider";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 100,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 50,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    itemRoot: {
        textDecoration: 'none'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    }
}));

const CashBalance = ({reload, ...props}) => {
    
    const classes = useStyles();
    const [count, setCount] = useState(-1);
    const [loading, setLoading] = useState(false);
    const {className} = props;
    const {state} = useContext(AppContext);
    // const [isShown, setIsShown] = useState(false);
    const [balances, setBalances] = useState(new Map());
    const updateBalances = (k,v) => {
        setBalances(new Map(balances.set(k,v)));
    }
    // const [totalBalance, setTotalBalance] = useState(0);

    React.useEffect(() => {
        if(state.branches.length > 0){

            setCount(state.branches.length);
            setLoading(true);
            state.branches.forEach(branch => {
                updateBalances(branch.id, {id: branch.id, name: branch.name, balance: 0})
                // FinanceService.getLedgers('5', branch.id, null, 0, 9999)
                // .then(data => {
                //     if(data && data.length > 0) {
                //         data.forEach(account => {
                //             FinanceService.getOpeningBalance(account.id, branch.id, moment().add(1, 'd'))
                //             .then((d) => {
                //                 if([...balances.keys()].indexOf(branch.id) === -1){
                //                     updateBalances(branch.id, {id: branch.id, name: branch.name, balance: d.balance || 0})
                //                 } else {
                //                     let b = balances.get(branch.id);
                //                     b.balance += d.balance || 0;
                //                     updateBalances(branch.id, b)
                //                 }
                //                 // setTotalBalance(tb => (tb + (d.balance || 0)));
                //                 // setLoading(false);
                //             })
                //             .catch(error => {
                //                 console.log(error);
                //                 console.log('Failed to fetch Opening Balance');
                //                 // setLoading(false);
                //             })
                //             setCount(count => (--count));
                //         })
                //     }
                // })
                // .catch(error => {
                //     console.log(error);
                // });
            });
        }

    }, [reload]);

    React.useEffect(() => {
        if(count === 0){
            setLoading(false);
        }
    }, [count])

    return (
        <Grid item xl={12} lg={12} xs={12}>
            <Card
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={'Current Cash Balance'}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}} />
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <List>
                            {
                                [...balances.keys()].indexOf("-1") !== -1 && (
                                    <ListItem
                                        classes={{ divider: classes.itemDivider }}
                                        style={{textDecoration: 'none'}}
                                        divider
                                        dense
                                        key={'All Entities'}
                                    >
                                            <ListItemText
                                                primary={'All Entities'}
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                            />
                                        <Typography color="inherit">{formatNumber([...balances.values()].filter(b => b.id !== '-1').reduce((sum, next) => sum + next.balance, 0) || 0)}</Typography>
                                    </ListItem>
                                )
                            }
                            {[...balances.values()].filter(b => b.id !== '-1').map(data => (
                                <ListItem
                                    classes={{ divider: classes.itemDivider }}
                                    style={{textDecoration: 'none'}}
                                    divider
                                    dense
                                    component={RouterLink}
                                    key={data.name}
                                    to={`/finance/accounts/report/cash/book?branch_id=${data.id}&startdate=${moment().format(format)}&enddate=${moment().format(format)}`}
                                >
                                        <ListItemText
                                            primary={data.name}
                                            primaryTypographyProps={{ variant: 'subtitle2' }}
                                        />
                                    <Typography color="inherit">{formatNumber(data.balance)}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    }
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CashBalance;